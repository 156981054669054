.wrapper-filter-form-side {
  padding-right: 1.5em;
  @media (orientation: portrait) {
    display: none;
  }
  .wrapper-common-tab .toggler {
    border-color: $pepe-mid-grey;
  }
  .wrapper-touch-checkbox {
    width: 100%;
  }
  .contact-banner {
    font-size: 8px;
    .contact-title {
      font-size: 1.9vw;
      background-size: 15px auto;
    }
    .contact-phone-main {
      font-size: 4.5vw;
    }
  }
}