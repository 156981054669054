.wrapper-car .wrapper-tab-summary {
  .wrapper-row-1 {
    height: $main-info-height;

    .wrapper-image {
      width: calc(100% - #{$main-info-width});
      padding: get-vw(10px);
    }
    .wrapper-main-info {
      width: $main-info-width;
    }
  }
  .wrapper-row-2 { height: $tab-extras-height; }
  .wrapper-row-3 { height: $tab-taxes-height; }

  .wrapper-row-2 {
    overflow: hidden;

    .wrapper-characteristics {
      width: calc(100% - #{$cancellation-width});
    }

    .wrapper-cancellation {
      width: $cancellation-width;
    }
  }

  .wrapper-row-1 .wrapper-main-info {

    ul.list {
      padding: 0 get-vw(5px);
      margin: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      li {
        padding-left: get-vw(25px);
        margin-bottom: get-vw(8px);

        background: url('https://dummyimage.com/24x24/c7c5c8/fff') no-repeat 5px center;
        background-size: get-vw(12px) get-vw(12px);
        min-height: get-vw(12px);

        &.provider {
          .rating {
            width: get-vw(25px);
          }

          .text img {
            max-width: get-vw(45px);
            max-height: get-vw(20px);
            height: auto;
          }
        }
      }
    }
  }

  .wrapper-row-2 .wrapper-cancellation {
    .inner {
      width: get-vw(42px);
    }

    .title {
      margin-bottom: get-vw(2px);
    }
    .value {
      background-size: get-vw(25px) get-vw(15px);
    }
  }

  .wrapper-row-3 {
    > * {
      height: $tab-taxes-height;
    }

    .wrapper-daily-price {
      width: calc(100% - #{$total-price-width});
    }
    .wrapper-total-price {
      width: $total-price-width;
    }
  }

  .wrapper-row-3 .wrapper-daily-price {
    line-height: $tab-taxes-height;
  }

  .wrapper-row-3 .wrapper-total-price {
    padding-right: get-vw(12px);
  }
}
