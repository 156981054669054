@import "../pepe-theme-sass-variables";

.icon-with-text {
  height: 24px;
  padding-left: 21px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  span {
    background: white;
    border-radius: 5px;
    padding: 0 5px;
    line-height: 1em;
  }

  background-image: url('https://dummyimage.com/48x48/c7c5c8/fff');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 24px 24px;

  max-width: 50%;

  $list: 'doors', 'people', 'fuel', 'air-condition', 'dimensions', 'km-limited', 'km-unlimited', 'transmission', 'gps',
  'additional-driver', 'wifi', 'kid-chair', 'debit-card-accepted-info';
  @each $key in $list {
    &.icon-#{$key} {
      background-image: url('../../static/img/icons/characteristics/#{$key}.svg');
    }
  }
}
