.wrapper-touch-select-placeholder {
  border-radius: 10px;
  line-height: 40px;
  padding: 0 12px;

  font-family: $font2;
  background: $pepe-mid-grey;

  margin-bottom: 10px;

  &:last-child {
    // margin-bottom: 0;
  }

  select {
    width: 100%;
  }

  .inner {
    background: url('../../../static/img/icons/wrapper-touch-select-placeholder.svg') no-repeat right center;
    background-size: 12px auto;

    img {
      width: 20px;
    }

    .value {
      max-width: calc(100% - 45px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .wrapper-icon {
    float: left;
    margin-right: 10px;
  }

  &.disabled { background: $pepe-dark-text; }
}

#SearchForm--Variant_B .touch-autocomplete-input {
  display: flex;
  height: 56px;
  background-color: white;
  .inner {
    display: flex;
    align-items: center;
    width: 100%;
    background: none;
    svg {
      margin-right: 16px;
    }
    .value {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.15px;
      color: #3B3B3B;
      &.placeholder {
        color: #D4D4D4;
      }
    }
  }
  .wrapper-icon {
    width: 16px;
    height: 16px;
    img {
      width: 100%;
    }
  }
}
