.wrapper-row-provider {
  overflow: hidden;
  height: $row-provider-height;

  .cancelation-box,
  .debit-card-box,
  .extras-box,
  .provider-info-box {
    padding: get-size(9px);
    height: 100%;
    float: left;
    &.has-review {
      cursor: pointer;
    }
  }

  .provider_review_more_info {
    color: $blue;
    cursor: pointer;

    &:hover { text-decoration: underline; }
  }

  > * {
    float: left;
    border-right: 1px solid $pepe-dark-grey;
    &:last-child { border-right: 0; }
  }

  .cancelation-box {

    .cancelation-text {
      width: get-size(25px);
      color: transparent;
      font-size: 1.17em;
      display: inline-block;
      margin-right: get-size(10px);
      margin-left: get-size(5px);
      //background: url($image-path + '/icons/24h.svg') no-repeat left center;
      //background-size: get-size(25px) auto;

      // @TODO: PEPE-1140 change svg or font
      color: $pepe-red;
      font-family: $font3;
      font-weight: bold;
      font-size: 1.05em;
      letter-spacing: 0.04em;
    }

    .fa {
      color: $pepe-red;
      vertical-align: super;
      font-size: 0.9em;
    }
  }

  .extras-box {
    .wrapper {
      overflow: hidden;
      padding: get-size(10px) 0;
      height: get-size(46px);
      display: flex;
      justify-content: space-between;

      .extras-box-img {
        display: block;
        float: left;
        width: 100%;
        background: transparent no-repeat center center;
        background-size: contain;
        &.free_additional_driver {
          background-image: url('../../../../../static/img/icons/characteristics/additional-driver.svg');
        }
        &.free_child_seat {
          background-image: url('../../../../../static/img/icons/characteristics/kid-chair.svg');
        }
        &.free_gps {
          background-image: url('../../../../../static/img/icons/characteristics/gps.svg');
        }
        &.free_wifi {
          background-image: url('../../../../../static/img/icons/characteristics/wifi.svg');
        }
      }
    }
  }

  .provider-info-box {
    .wrapper {
      padding: get-size(10px) 0;
    }
    .box-extra-info-img {
      width: 60%;
      padding-right: get-size(20px);
      display: inline-block;
    }
    .box-extra-info-rating {
      width: 40%;
      font-size: 1.28em;
      font-family: $font4;
      color: $pepe-dark-grey;
      display: inline-block;
      text-align: center;
      span {
        font-size: 0.8em;
      }
    }
  }

  .debit-card-box {
    .debit-card-img {
      margin-right: get-size(10px);
    }
    .fa {
      color: $pepe-red;
      vertical-align: super;
      font-size: 0.9em;
    }
  }

  &.cols-1 {
    .cancelation-box,
    .debit-card-box,
    .extras-box,
    .provider-info-box {
      width: 50%;
    }
  }
  &.cols-2 {
    .cancelation-box,
    .debit-card-box,
    .extras-box,
    .provider-info-box {
      width: 50%;
    }
  }
  &.cols-3 {
    .cancelation-box,
    .debit-card-box,
    .extras-box {
      width: 25%;
    }
    .provider-info-box {
      width: 50%;
    }
  }
  &.cols-4 {
    .cancelation-box,
    .debit-card-box,
    .extras-box,
    .provider-info-box {
      width: 25%;
    }

    .provider-info-box {
      .wrapper {
        padding: 0;
      }
      .box-extra-info-img {
        padding-right: 0;
        width: 100%;
      }
    }
  }
}
