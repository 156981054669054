@import "../../../sass/pepe-theme-sass-variables";

$width: 800px !default;
@import 'sass/variables';

@import "sass/layout";
@import "sass/header";
@import "sass/recommended";
@import "sass/adjustments";
@import "car-image/component";
@import "car-cross-selling/component";
@import "car-booking/component";
@import "car-details/component";
@import "car-includes/component";
@import "car-provider-review-details/component";


.terms-conditions-title {
  padding-bottom: 20px;
}

.terms-chapter-title {
  font-size: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
}