.wrapper-car .wrapper-tab-summary {
  .wrapper-row-1 {
    overflow: hidden;

    .wrapper-image {
      float: left;
      border-right: 1px solid $pepe-dark-grey;
      height: 100%;

      display: flex;
      flex-direction: column;
      justify-content: center;

      img {
        max-width: 100%;
        height: auto;
      }
    }
    .wrapper-main-info {
      float: left;
      height: 100%;
    }
  }

  .wrapper-row-2 {
    overflow: hidden;

    > * {
      float: left;
      height: 100%;
    }

    .wrapper-characteristics {
      border-right: 1px solid $pepe-dark-grey;
    }
  }

  .wrapper-row-1 .wrapper-main-info {
    display: flex;
    flex-direction: column;
    justify-content: center;


    ul.list {
      margin: 0;
      height: 100%;
      display: flex;
      flex-direction: column;

      li {
        list-style: none;

        text-transform: uppercase;
        font-family: $font2;
        font-weight: bold;
        font-size: 0.5em;
        line-height: 1.3em;


        &:last-child {
          margin-bottom: 0;
        }

        &.airport {
          background-image: url('../../../../../../static/img/icons/main-info/airport.svg');
        }
        &.railway {
          background-image: url('../../../../../../static/img/icons/main-info/railway.svg');
        }
        &.city {
          background-image: url('../../../../../../static/img/icons/main-info/city.svg');
        }
        &.fuel-policy {
          background-image: url('../../../../../../static/img/icons/main-info/fuel-policy.svg');
        }
        &.provider {
          padding-left: 0;
          background: none;

          display: flex;
          align-items: stretch;

          .rating {
            text-align: center;
            flex-shrink: 0;
            color: $pepe-dark-grey2;

            font-weight: normal;
            font-family: $font4;

            span { display: block; }
            .rating-value {
              font-size: 1.25em;
            }
            .rating-max {
              font-size: 0.9em;
            }
          }

          .text {
            text-transform: none;

            .description { display: block; }
            img {
              height: auto;
            }
          }
        }
      }
    }
  }

  .wrapper-row-2 .wrapper-characteristics {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;

    ul.list {
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: space-around;

      li { list-style: none; }
    }
  }
  .wrapper-row-2 .wrapper-cancellation {
    display: flex;
    justify-content: center;
    flex-direction: column;

    p { margin: 0; line-height: 1.1em; }

    .inner {
      margin: 0 auto;
    }

    .title {
      font-family: $font2;
      font-size: 0.5em;
      line-height: 1em;
    }
    .value {
      //background: url($image-path + '/icons/24h.svg') no-repeat left center;
      //color: transparent;

      // @TODO: PEPE-1140 change svg or font
      color: $pepe-red;
      font-family: $font3;
      font-weight: bold;
    }
  }

  .wrapper-row-3 {
    overflow: hidden;

    > * {
      float: left;
    }

  }

  .wrapper-row-3 .wrapper-daily-price {
    color: $pepe-red;
    text-align: center;
    font-weight: bold;
    font-size: 1.4em;
  }

  .wrapper-row-3 .wrapper-total-price {
    text-align: right;
    line-height: 1.2em;
    font-weight: bold;
    p { margin-bottom: 0; }

    .title {
      font-size: 0.8em;
      text-transform: uppercase;
    }
    .price {
      font-size: 1.4em;
    }
    .tax { font-size: 0.8em; }
  }
}
