$path: '../static/fonts';
$MontserratRegular: '#{$path}/Montserrat/Regular/Montserrat';
$MontserratBold: '#{$path}/Montserrat/Bold/Montserrat';
$MontserratMedium: '#{$path}/Montserrat/Medium/Montserrat';
$MontserratSemiBold: '#{$path}/Montserrat/SemiBold/Montserrat';
$Sarabun: '#{$path}/Sarabun';

@font-face {
  font-family: 'Montserrat';
  src: url('#{$MontserratRegular}-Regular.woff2') format('woff2'),
  url('#{$MontserratRegular}-Regular.woff') format('woff'),
  url('#{$MontserratRegular}-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat-Bold';
  src: url('#{$MontserratBold}-Bold.woff2') format('woff2'),
  url('#{$MontserratBold}-Bold.woff') format('woff'),
  url('#{$MontserratBold}-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat-Medium';
  src: url('#{$MontserratMedium}-Medium.woff2') format('woff2'),
  url('#{$MontserratMedium}-Medium.woff') format('woff'),
  url('#{$MontserratMedium}-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('#{$MontserratSemiBold}-SemiBold.woff2') format('woff2'),
  url('#{$MontserratSemiBold}-SemiBold.woff') format('woff'),
  url('#{$MontserratSemiBold}-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Sarabun';
  src: url('#{$Sarabun}/Sarabun-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}