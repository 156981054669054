.wrapper-common-tab {
  margin-bottom: 5px;
  .toggler {
    line-height: 40px;
    border-bottom: 1px solid $white;
    background: url('../../../static/img/icons/tab-open.svg') no-repeat right center;
    background-size: 15px auto;

    .title {
      font-family: $font2;
      padding-left: 5px;
      font-weight: bold;
    }
  }

  .content {
    > .inner { padding: 15px 5px; }
  }

  &.collapsed {
    .toggler {
      background-image: url('../../../static/img/icons/tab-closed.svg');
    }
  }
}
