.wrapper-mode-list .mode-list-inner-wrapper {
    display: flex;
    justify-content: center;
    padding: 0 1em;

    @import "../../touch/filter-form-sidebar/component";
}

.wrapper-notice {
  width: $width;
  margin-bottom: 20px;
}

.wrapper-car-list {
  .no-results {
    border: 1px solid $pepe-red;
    border-radius: 10px;
    font-size: 1.2em;
    padding: 10px;
  }
}