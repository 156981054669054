.insurance-info {
    padding-left: 2em;
    padding-right: 2em;
    .ngdialog-content {
      width: 70% !important;
      @media (max-width: 992px) {
        width: 100% !important;
       }
    }
    .modal-title {
      text-transform: none !important;
      font-size: 1.3em !important;
      margin-bottom: 20px;
      font-family: $font2 !important;
      letter-spacing: 0px !important;
    }
    .insurance-info-card {
      margin-top: 35px;
      border-radius: 2px;
      padding: 10px 10px 10px 100px;
      background: url("../../../../../../static/img/icons/icon-insurance-card.png") center left 10px no-repeat #F5F5F5;
    }
}
