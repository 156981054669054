.help-text {
  color: #8E8E8E;
  margin-bottom: 10px;
}

.extra-text-wrapper {
  margin: 30px 0;
}

.form-error {
  text-align: left;
}

.form-item.birthdate-wrapper {
  margin-bottom: 15px;
}