@import "../../../sass/pepe-theme-sass-variables";

.wrapper-fixed-footer {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 5;
  height: 50px;
  line-height: 48px;
  background: $pepe-mid-grey;
  border-top: 2px solid $pepe-dark-grey2;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.3em;

  @media (orientation: landscape) {
    display: none;
  }

  //&:after {
  //  display: block;
  //  content: '';
  //  width: 17px;
  //  height: 14px;
  //  position: absolute;
  //  top: 0; bottom: 0;
  //  left: 12px;
  //  margin: auto 0;
  //  background: url($image-path + '/icons/filters.svg') no-repeat;
  //  background-size: 12px 10px;
  //}
}

#search-app.search-app-tablet {
  .wrapper-fixed-footer {
    height: 65px;
    line-height: 63px;
  }
}
