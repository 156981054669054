@import "../../components/mobile/search-form/component";

.wrapper-touch-search-form {
  .wrapper-popup-inner > .inner {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .column {
      width: 300px;
      margin: 0 20px;
    }
  }
}
