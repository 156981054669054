
$header-height: 50px;
$footer-height: 80px;

@function get-vw-provider($target) {
  $design-width: 675;
  $vw-context: ($design-width * .01) * 1px;
  @return ($target / $vw-context) * 1vw;
}

$provider-font-size: get-vw-provider(14px);

@function get-em-provider($target) {
  @return (get-vw-provider($target) / $provider-font-size) * 1em;
}

.wrapper-submode-price-table-tablet {
  &.with-header .wrapper-popup-inner {
    height: calc(100% - #{$header-height});
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 4em;

    .wrapper-price-table-type,
    .wrapper-price-table-provider-list {
      width: 100%;
      margin-bottom: 2em;
      & > .section-title {
        line-height: 40px;
        border-bottom: 1px solid #ffffff;
        font-family: $font2;
        padding-left: 5px;
        font-weight: bold;
        margin-bottom: 2em;
      }
      & > .content {
        position: relative;
      }
    }

    .wrapper-price-table-type {
      position: relative;
      .content {
        height: 90px;
      }
      .prev,
      .next {
        position: absolute;
        height: $footer-height;
        width: 30px;
        color: transparent;
        top: 0;
        z-index: 999;
      }
      .prev {
        left: 0;
        background: url('../../../static/img/icons/row-left.svg') no-repeat;
        background-position: center center;
      }
      .next {
        right: 0;
        background: url('../../../static/img/icons/row-right.svg') no-repeat;
        background-position: center center;
      }
      .wrapper-list {
        overflow-x: auto;
        height: 100%;
      }
      .price-table-type-list {
        color: $pepe-black-text;
        font-family: $font2;
        font-size: 1.5em;
        margin: 0 auto;
        height: 100%;
        padding: 0;
        overflow-x: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: nowrap;
        text-transform: uppercase;
        width: calc(100% - 100px);
        li {
          font-size: 1em;
          transition: font-size 1s;
          margin: 0 10px;
          white-space: nowrap;
          text-align: center;
          list-style: none;
          min-width: 120px;
          &.active {
            font-size: 1.4em;
            font-weight: bold;
          }
        }
        .price {
          color: white;
        }
      }
    }

    .price-table-provider-list {
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      font-size: $provider-font-size;
      height: get-em-provider(140px);
      li {
        width: get-em-provider(180px);
        height: get-em-provider(30px);
        margin: 0 auto;
        margin-bottom: get-em-provider(10px);
        list-style: none;
        border-radius: get-em-provider(15px);
        background: $white;
        overflow: hidden;
        &.type-price {
          .title {
            width: get-em-provider(85px);
          }
          .wrapper-price {
            width: get-em-provider(95px);
            text-align: center;
            padding-right: 0;
          }
        }
        &.provider-price {
          .wrapper-image {
            width: get-em-provider(70px);
          }
          .wrapper-price {
            width: get-em-provider(110px);
          }
        }
        .wrapper-image,
        .title {
          float: left;
          height: get-em-provider(30px);
        }
        .title {
          line-height: get-em-provider(30px);
          width: get-em-provider(70px);
          text-align: center;
          text-transform: uppercase;
        }
        .wrapper-image {
          padding-left: get-em-provider(10px);
          display: flex;
          flex-direction: column;
          justify-content: center;
          img {
            max-width: get-em-provider(50px);
            height: auto;
            max-height: get-em-provider(30px);
          }
        }
        .wrapper-price {
          float: left;
          background: $grey;
          line-height: get-em-provider(30px);
          padding-right: get-em-provider(15px);
          text-align: center;
          font-family: $font2;
        }
      }
    }

  }
}
