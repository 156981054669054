@import "../../../../sass/pepe-theme-sass-variables";

$bubble-icon-size: 22px;

.car-info-bubble {
  position: relative;
  display: inline-block;

  .car-info-bubble-img {
    display: inline-block;
    background-color: $pepe-dark-grey;
  }

  .car-info-bubble-text {
    font-family: $font2;
    text-align: left;
    position: absolute;
    border-radius: 15px;
    color: $pepe-black-text;
    background-color: white;

    &.car-info-bubble-detail-red {
      background-color: $pepe-red;
    }
  }
}
