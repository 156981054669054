@import "pepe-theme-sass-variables";

/* Helper classes */
.no-padding {
  padding: 0 !important;
}
.no-margin {
  margin: 0 !important;
}

.wrapper-img-max-width img {
  max-width: 90px;
  height: auto;
}

footer {
  background-color: white;
}

.inner-vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.pepe-col-1, .pepe-col-2, .pepe-col-3, .pepe-col-4,  .pepe-col-5,  .pepe-col-6,  .pepe-col-7, .pepe-col-8, .pepe-col-9, .pepe-col-10, .pepe-col-11, .pepe-col-12 {
  position: relative;
  min-height: 1px;
  height: 100%;
  display: inline-block;
  vertical-align: top;
}

.pepe-col-12 {
  width: 100%;
}

.pepe-col-11 {
  width: 91.66666667%;
}

.pepe-col-10 {
  width: 83.33333333%;
}

.pepe-col-9 {
  width: 75%;
}

.pepe-col-8 {
  width: 66.66666667%;
}

.pepe-col-7 {
  width: 58.33333333%;
}

.pepe-col-6 {
  width: 50%;
}

.pepe-col-5 {
  width: 41.66666667%;
}

.pepe-col-4 {
  width: 33.33333333%;
}

.pepe-col-3 {
  width: 25%;
}

.pepe-col-2 {
  width: 16.66666667%;
}

.pepe-col-1 {
  width: 8.33333333%;
}

.pepe-detail-text {
  font-size: 0.7em;
  line-height: 1em;
  margin-bottom: 0.28em;
  font-weight: normal;
  font-family: $font5;
}

.pepe-title-text {
  font-size: 1.5em;
  font-weight: normal;
  text-transform: uppercase;
}

.pepe-grey-box {
  border: 1px solid $pepe-dark-grey;
  color: $pepe-dark-text;
  background-color: $pepe-grey;
  text-align: center;
  .pepe-detail-text {
    font-family: $font2;
    font-weight: bold;
  }
}

.pepe-focus-text {
  font-size: 2em;
  margin-bottom: 0.28em;
  font-weight: normal;
  text-transform: uppercase;
}

.pepe-nav-button {
  min-width: 15px;
  height: 15px;
  border: 1px solid $pepe-dark-grey;
  background-color: white;
  cursor: pointer;
}

.pepe-popup-close-button {
    width: 100%;
    position: relative;
    display: inline-block;
    overflow: hidden;
    height: 50px;
    line-height: 50px;
    margin-bottom: 20px;
    text-align: center;
    cursor: pointer;
    font-size: 1.71em;
    font-family: $font4;
    color: white !important;
    background-color: $pepe-red;
}

.pepe-icon {
  display: inline-block;
}

.pepe-icon-plane {
  background: url('../static/img/icons/main-info/airport.svg') no-repeat center;
  background-size: cover;
}

.pepe-icon-city {
  background: url('../static/img/icons/location-icons/city.png') no-repeat center;
  background-size: cover;
}

.pepe-icon-city-v2 {
  background: url('../static/img/icons/location-icons/city-v2.svg') no-repeat center;
  background-size: contain;
}

.pepe-icon-city-marker-v2 {
  background: url('../static/img/icons/location-icons/city-marker-v2.svg') no-repeat center;
  background-size: contain;
}

.pepe-icon-railway {
  background-image: url('../static/img/car-v2/ico-railway-station.svg');
  //background: url($image-path + '/icons/location-icons/railway.png') no-repeat center;
  background-size: cover;
}

.pepe-icon-gas {
  background: url('../static/img/icons/main-info/fuel-policy.svg') no-repeat center;
  background-size: contain;
}
.pepe-icon-checked {
  background: url('../static/img/icons/checkbox/checked.svg') no-repeat center;
  background-size: cover;
}

.pepe-icon-not-checked {
  background: url('../static/img/icons/checkbox/not-checked.svg') no-repeat center;
  background-size: cover;
}

.pepe-icon-btn-close {
  background: url('../static/img/icons/btn-close.svg') no-repeat center;
  background-size: cover;
}

.pepe-icon-tab-closed {
  background: url('../static/img/icons/tab-closed.svg') no-repeat center;
  background-size: cover;
}

.pepe-icon-tab-open {
  background: url('../static/img/icons/tab-open.svg') no-repeat center;
  background-size: cover;
}

$characteristics: 'doors', 'air-condition', 'dimensions', 'fuel', 'km-limited', 'km-unlimited', 'people', 'transmission', 'additional-driver', 'gps', 'wifi', 'luggage-carrier', 'skiboxes', 'car-chains';
@each $key in $characteristics {
  .pepe-icon-#{$key}{
    background: url('../static/img/icons/characteristics/#{$key}.svg') no-repeat center;
    background-size: cover;
  }
}

$taxesIcons: 'airport-tax', 'young-driver', 'one-way', 'nightly', 'full-empty-refund-tax','others';
@each $key in $taxesIcons {
  .pepe-icon-#{$key}{
    background: url('../static/img/icons/taxes/#{$key}.svg') no-repeat center;
    background-size: cover;
  }
}

$babyIcons: '7', '8', '9';
@each $key in $babyIcons {
  .pepe-icon-#{$key}{
    background: url('../static/img/icons/kid-chairs/#{$key}.svg') no-repeat center;
    background-size: cover;
  }
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
