body {
  //padding-bottom: 50px;

  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

select {
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
