@import "../../../sass/pepe-theme-sass-variables";

.insurance-banner-v2 {
  font-family: $font2;
  background: $white;
  border: 1px solid $pepe-dark-text;
  .content {
    padding: 60px 50px 30px;
    .content-header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 45px;
      .content-column {
        max-width: 600px;
      }
      .insurance-banner-title {
        text-transform: uppercase;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 6px;
        color: #000000;
        margin-bottom: 10px;
      }
      .insurance-banner-subtitle {
        font-size: 17px;
        letter-spacing: 6px;
        line-height: 25px;
        margin-bottom: 15px;
      }
      .insurance-banner-description {
        font-size: 14px;
        line-height: 22px;
      }
      .insurance-banner-image {
        width: 155px;
        img {
          width: 100%;
        }
      }
    }
    .content-tables {
      display: flex;
      justify-content: space-between;
      margin-bottom: 45px;
      .insurance-table {
        width: 505px;
        border: 1px solid;
        &:last-of-type {
          margin-right: 0;
        }
        .table-header {
          padding: 15px 30px;
          font-weight: bold;
          text-transform: uppercase;
          font-size: 14px;
        }
        .table-content {
          .table-row {
            padding: 15px 30px;
            border-bottom: 1px solid $pepe-dark-grey;
            font-size: 13px;
            &:last-of-type {
              border-bottom: none;
            }
          }
        }
        &.limited {
          .table-header {
            background: $pepe-grey;
          }
          .table-content {
            .table-row {
              background: url('../../../static/img/icons/insurance-v2/not-included.svg') no-repeat right 30px center;
            }
          }
        }
        &.premium {
          .table-header {
            background: $black;
            color: $white;
          }
          .table-content {
            .table-row {
              font-weight: bold;
              background: url('../../../static/img/icons/insurance-v2/included.svg') no-repeat right 30px center;
            }
          }
        }
      }
    }
    .content-footer {
      .description {
        font-size: 10px;
        line-height: 22px;
        margin-bottom: 30px;
        text-align: justify;
        a {
          text-decoration: underline;
        }
      }
      .explain {
        padding-left: 30px;
        background: url('../../../static/img/icons/insurance-v2/information-bubble.svg') no-repeat left center;
        font-size: 14px;
        color: $red;
        line-height: 22px;
        cursor: pointer;
      }
    }
  }
  .footer {
    background: $insurance-blue;
    padding: 20px 50px;
    color: $insurance-blue-text;
    font-size: 14px;
    cursor: pointer;
    .checkbox-label {
      cursor: pointer;
      margin-left: 14px;
    }
  }

  .input-wrap {
    position: relative;
    margin: 0 auto 1em;
    display: inline-block;
    vertical-align: top;
    width: 370px;
    height: 80px;
  }
  .input-wrap--small {
    width: 30px;
    height: 30px;
    float: left;
    margin-top: -5px;
  }
  .input-wrap input[type="text"] {
    display: block;
    width: 94.6%;
    height: 75%;
    border: none;
    position: relative;
    background: none;
    z-index: 100;
    outline: none;
    font-size: 1.5em;
    font-weight: bold;
    color: #aaa;
    left: 2.7%;
    top: 12.5%;
    padding: 0 0.75em;
    -webkit-tap-highlight-color: transparent;
    /* For some Androids */
  }
  .input-wrap input[type="checkbox"],
  .input-wrap input[type="radio"],
  .input-wrap .fa {
    position: absolute;
    /*width: 100%;
    height: 100%;
    z-index: 100;*/
    opacity: 0;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    /* For some Androids */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .input-wrap .fa {
    z-index: 99;
    line-height: 30px;
    color: $insurance-blue-text;
    font-size: 23px;
  }
  .input-wrap input[type="checkbox"]:checked + .fa,
  .input-wrap input[type="radio"]:checked + .fa {
    opacity: 1;
  }
  .morph-shape {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
  }
  .morph-shape svg {
    border: 2px solid $insurance-blue-text;
    background-color: $white;
  }
  .morph-shape svg path {
    fill: transparent;
  }
}