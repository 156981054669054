.wrapper-search-box.tablet {
  .wrapper-touch-checkbox {
    width: 100%;
  }
  .wrapper-time {
    padding: 0;
    .wrapper-touch-select-placeholder {
      width: 100%;
      margin-bottom: 10px;
      border-radius: 0;
      background: $pepe-grey;
      input {
        width: 100%;
      }
    }
  }

  .filled-button {
    &:hover { background-color: $pepe-red; }
  }
  .margin-bottom-12 {
    margin-bottom: 12px;
  }
}

#SearchForm--Variant_B .SearchForm__Inner {
  max-height: 580px;

  .wrapper-search-box.tablet {
    background: transparent !important;
    border: unset !important;
    //height: calc(100vh - 60px) !important;

    .tab-content {
      padding: 16px !important;
      height: 100% !important;
      display: flex !important;
      flex-direction: column !important;
      .btn.btn_search {
        font-family: $font2 !important;
        margin-top: 0px !important;
        border-radius: 3px;
        background: #D6322C !important;
        height: 56px;
        font-weight: 700 !important;
        font-size: 15px !important;
        line-height: 26px !important;
        text-align: center !important;
        letter-spacing: 0.46px !important;
        text-transform: uppercase !important;
      }
      #search-box-title {
        width: 500px !important;
        .SearchBoxTitle__Title {
          font-family: $font2 !important;
          font-style: normal !important;
          font-weight: 700 !important;
          font-size: 24px !important;
          line-height: 120% !important;
          letter-spacing: -0.5px !important;
          color: $white !important;
          text-transform: none !important;
          padding: 8px !important;
        }
      }
    }

    .search-box-form {
      gap: 0 !important;
      margin-top: 0 !important;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      > div {
        width: 100%;
      }
    }

    .wrapper-location-input {
      height: 56px;
      width: 100%;
      &.dual-top {
        .wrapper-touch-select-placeholder {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      &.dual-bottom {
        border-top: 1px solid #D4D4D4;
        margin-bottom: 8px;
        .wrapper-touch-select-placeholder {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
      }
      .wrapper-touch-select-placeholder {
        height: 100%;
      }
    }

    .form-group {
      margin-bottom: 0 !important;
    }

    .wrapper-checkbox-same-office {
      display: flex;
      color: white;
      align-items: center;
      padding: 12px 8px;
      .checkbox-same-office {
        width: 16px;
        height: 16px;
        background: #FFFFFF;
        border-radius: 2px;
        margin-right: 8px;
      }
      span {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.4px;
        color: #D4D4D4;
      }
    }

    .diff-office-close {
      position: absolute;
      right: 0;
      top: 0;
      height: 56px;
      display: flex;
      align-items: center;
      padding: 0 16px;
    }

    .dropoff-input-wrapper {
      position: relative;
    }
    .error-message {
      display: none;
      width: 100%;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.4px;
      color: #FBA453;
      margin-left: 40px;
    }
    .withRACSearchFormError {
      display: flex;
      flex-direction: column;
      .date-time-input,
      .wrapper-location-input > div {
        box-shadow: inset 0px 0px 0px 3px rgba(251, 164, 83, 0.5);
      }
      .wrapper-checkbox-same-office {
        order: 2;
      }
      .error-message {
        order: 1;
        display: block;
      }
    }
    .date-time-input .withRACSearchFormError {
      flex-direction: row !important;
    }
  }
}
