.car-result-box .recommended-car {
  position: absolute;
  top: get-size(4px);
  right: get-size(-8px);
  text-transform: uppercase;
  font-size: .714em;
  background: url('../../../../static/img/car-details/bg-recommended-car.png') center left 0 no-repeat;
  background-size: cover;
  height: get-size(30px);
  width: get-size(125px);
  padding-top: get-size(9px);
  color: white;
  padding-left: get-size(30px);
  font-family: $font2;
}
