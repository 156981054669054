.wrapper-autosuggest-modal {
  .react-autosuggest__container {
    input {
      width: 100%;
      line-height: 35px;
      padding: 0 15px;
      font-family: $font2;
      box-sizing: border-box;

      margin-bottom: 10px;
    }
  }
  .react-autosuggest__suggestions-container {
    height: calc(100vh - 120px);
    overflow-y: auto;

    .react-autosuggest__suggestions-list {
      padding: 0;

      li {
        list-style: none;
        padding: 14px 20px;
        font-size: 1.2em;

        &:nth-child(2n) {
          background: $pepe-mid-grey;
        }
      }
    }

    .wrapper-suggestion {
      .wrapper-image {
        float: left;
        margin-right: 5px;

        img {
          max-width: 16px;
          height: auto;
        }
      }

      .wrapper-flag {
        float: left;
        margin-right: 10px;

        .flag-icon {
          width: 0.8em;
        }
      }
    }
  }
}

.custom-touch-autosuggest {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__input-container {
    padding: 0 16px 12px;
    width: 100%;
    border-bottom: 1px solid #D4D4D4;
    position: fixed;
    z-index: 10;
    background-color: $pepe-black-text;
    svg {
      position: absolute;
      top: 16px;
      left: 32px;
    }
  }
  &__empty {
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.5px;
    color: #5F5F5F;
    position: absolute;
    top: 50%;
    width: 100%;
  }
  .react-autosuggest {
    &__input {
      background: $white;
      border-radius: 12px;
      border: unset;
      width: 100%;
      height: 56px;
      padding: 16px 16px 16px 56px;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.15px;
      color: $grey-dark2;
      &--focused {
        border: 3px solid rgba(37, 148, 206, 0.3);
      }
    }
    &__suggestions-container {
      &--open {
        padding: 88px 8px 8px;
        width: 100%;
      }
      .wrapper-suggestion {
        color: $white;
        align-items: center;
        display: flex;
        // gap: 10px;
        padding: 4px 16px;
        &-highlighted {
          background-color: #4B4B4B;
        }
        .wrapper-image {
          margin-right: 10px;
          img {
            -webkit-filter: invert(100%); /* Safari/Chrome */
            filter: invert(100%);
            height: 16px;
            width: 16px;
          }
        }
        .wrapper-name {
          display: flex;
          flex-direction: column;
          width: 100%;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.15px;
          .wrapper-name-subtitle {
            display: flex;
            gap: 8px;
            font-weight: normal;
            font-size: 10px;
            line-height: 14px;
            align-items: center;
            .wrapper-flag {
              display: flex;
              height: 8px;
              .flag-icon {
                width: 12px;
                height: 8px;
              }
            }
          }
        }
      }
    }
    &__suggestions-list {
      margin-bottom: 8px;
      padding: 0;
    }
    &__section-title {
      font-weight: normal;
      font-size: 10px;
      line-height: 14px;
      color: $white;
      padding: 0 16px;
    }
  }
}
