/* Sizes */

/* The result box is design with a 740px width in mind, if we want a bigger box we just
 * apply this magic number to scale properly
 */

@function get-size($target) {
  $design-width: 760px;
  $aspect-ratio: $width / $design-width;
  @return ($target * $aspect-ratio);
}

$width: get-size(760px);
$height: get-size(335px);
$header-height: get-size(37px);

$inner-height: get-size(335px - 37px - 2px);

$col-image-width: get-size(265px);
$col-info-width: get-size(300px);
$col-price-width: $width - $col-image-width - $col-info-width - 3px;
