.wrapper-car .wrapper-layout .wrapper-tabs .wrapper-tab-active .inner-tab {
  &.info {
    > .content {
      font-family: $font2;
      font-size: 0.7em;

      .title {
        font-size: 0.9em;
        font-weight: bold;
      }
      .value {
        text-transform: uppercase;
        font-weight: bold;
      }
      .description {
        color: $pepe-dark-text;
        font-size: 0.9em;

        br {
          content: '';
          display: block;
          height: 1em;
        }
      }
    }
    &.active {
      .toggler {
        color: transparent;
        overflow: hidden;
      }
    }
  }


  &.provider {
    .toggler {
      .hidden-active {
        overflow: hidden;

        .rating {
          float: left;
          text-align: center;
          color: $pepe-dark-grey2;
          line-height: 1em;
          font-family: $font4;
          font-size: 0.7em;
          .max {
            font-size: 0.6em;
          }
        }
        .info {
          float: left;

          .title {
            font-weight: bold;
            font-size: 0.5em;
            line-height: 1em;
            font-family: $font2;
          }
          img {
            height: auto;
          }
        }
      }
    }

    .content {
      .header {
        display: flex;
        align-items: stretch;

        .wrapper-image {
          img {
            height: auto;
          }
        }

        .wrapper-rating {
          font-size: 0.6em;
          line-height: 1em;
          font-family: $font2;
          font-weight: bold;
          color: $pepe-red;

          .rating .value {
            font-size: 1.7em;
          }
          .rating .max {
            font-weight: normal;
            font-size: 0.9em;
            color: $pepe-dark-grey2;
          }
        }

        .wrapper-count {
          border-top: 3px solid $pepe-red;
          font-size: 0.65em;
          text-align: center;
          flex-grow: 2;
          color: $pepe-red;
          line-height: 2.2em;
          background: $pepe-dark-grey2;
        }
      }
      .wrapper-table {
        font-family: $font2;
        font-size: 0.5em;

        table {
          width: 100%;

          td.rating {
            box-sizing: border-box;
            text-align: center;
            color: $pepe-red;
            border-right: 1px solid $pepe-red;
          }

          tr:nth-child(2n) {
            td { background: $pepe-dark-grey; }
          }

          tr.promoted {
            td.rating { border-right-width: 3px; }
          }
        }
      }
    }
  }
}
