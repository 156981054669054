@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}
}

#SearchForm--Variant_B.SearchForm--Home {
  max-width: unset !important;

  .SearchForm__Inner {
    padding: unset !important;
    background-image: url('../../../static/img/foto-portada-3.jpg') !important;
    max-height: 380px;

    @media (max-width: 1280px) {
      max-height: 560px;
    }

    .container {
      // width: 100%;
      max-width: 1140px;
      padding: 48px 0 36px 0;
      margin: 0 auto;

      #search-box {
        box-shadow: none;
      }

      .search-box-desktop {
        #search-box {
          background: none;
          font-family: Montserrat,sans-serif;
          position: unset;
          width: 100%;

          .tab-content {
            form {
              padding: 0 !important;
            }
            padding: 0;
            #search-box-title {
              max-width: 765px;
              @media (max-width: 1280px) {
                margin: 0 auto;
                text-align: center;
              }
              .SearchBoxTitle__Title {
                font-family: $font2;
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 133.4%;
                color: #FFFFFF;
                position: relative;
                z-index: 1;
                text-transform: none;
                letter-spacing: inherit;
                margin-bottom: 50px;

                span {
                  display: inline-block;
                  position: relative;

                  &::before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    z-index: -1;
                    transform: rotate(-0.87deg);
                    background-color: #449C48;
                    bottom: 0;
                    left: 0;
                  }
                }
              }
            }

            button.filled-button {
              background: #D6322C;
              border-radius: 3px;
              width: 160px;
              height: 56px;
              border: 0;

              font-weight: 700;
              font-size: 15px;
              line-height: 26px;
              text-align: center;
              letter-spacing: 0.46px;
              text-transform: uppercase;
              color: #FFFFFF;
              &:hover {
                background: #c00;
              }
              @media (max-width: 1280px) {
                width: 500px;
                order: 2;
              }
            }

            .search-box-form {
              margin-top: 24px;
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              gap: 16px;
              @media (max-width: 1280px) {
                width: 500px;
                margin-left: auto;
                margin-right: auto;
              }
              .desktop-predictive {
                margin-bottom: 0 !important;
                .location-item-type {
                  top: 16px;
                  left: 8px;
                }
              }
              &__same-office-cbox {
                display: flex;
                align-items: center;
                gap: 8px;
                padding: 12px 0 12px 24px;
                margin-left: 8px;
                cursor: pointer;
                position: relative;
                /* Hide the browser's default checkbox */
                input {
                  position: absolute;
                  opacity: 0;
                  height: 0;
                  width: 0;
                  /* Show the checkmark when checked */
                  &:checked ~  label .checkmark:after {
                    display: block;
                  }
                }
                /* Create a custom checkbox */
                .checkmark {
                  position: absolute;
                  top: 12px;
                  left: 0;
                  height: 16px;
                  width: 16px;
                  background-color: white;
                  border-radius: 2px;
                  /* Create the checkmark/indicator (hidden when not checked) */
                  &:after {
                    content: "";
                    position: absolute;
                    display: none;
                    left: 5px;
                    top: 0px;
                    width: 6px;
                    height: 13px;
                    border: solid black;
                    border-width: 0 1px 1px 0;
                    -webkit-transform: rotate(42deg);
                    -ms-transform: rotate(42deg);
                    transform: rotate(42deg);
                  }
                }
                label {
                  font-style: normal;
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 20px;
                  letter-spacing: 0.4px;
                  color: #D4D4D4;
                  margin: 0;
                  cursor: pointer;
                }
              }
            }
          }
          .offices-selector-input {
            display: flex;
            align-items: center;
            width: 447px;
            @media (max-width: 1280px) {
              order: 0;
              width: 500px;
            }
          }
          .date-time-input {
            @media (max-width: 1280px) {
              order: 1;
            }
          }
          .vertical-separator {
            height: 24px;
            width: 1px;
            background-color: #D4D4D4;
            &.vertical-separator-1 {
              @media (max-width: 1280px) {
                display: none;
              }
            }
            &.vertical-separator-2 {
              @media (max-width: 1280px) {
                order: 1;
              }
            }
          }
        }
      }
    }
  }
}

.wrapper-search-box {
  margin: 40px;
  position: relative;
  padding: 0;
  &.collapsed {
    z-index: 0;
    margin: -40px -600rem 0;
    padding: 40px 600rem 1.25rem;
    background: #fbfbfb;
  }
  .search-details {
    color: #333;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    padding: 0 50px 10px;
    position: relative;

    .location-box {
      display: inline-block;
      width: 260px;
      margin-right: 40px;

      .location-box-title {
        font-size: 12px;
        color: #7a7a7a;
      }
      .location-name {
        font-size: 16px;
      }
      .datetime {
        font-size: 17px;
        color: #9d9d9d;
        span {
          display: inline-block;
          margin-right: 15px;
        }
      }
    }

    .wrapper-expand {
      position: absolute;
      bottom: 15px;
      right: 70px;
      .btn-expand {
        border: 1px solid #191918;
        background: #fbfbfb;
        text-transform: uppercase;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        padding: 0 40px;
        line-height: 35px;
      }
    }
  }

  .horizontal-search-box {
    box-shadow: none;
    border: 1px solid $pepe-dark-grey;
    border-bottom: none;
    position: relative;
    top: 0;
    width: 100%;
    z-index: auto;
    background: white;
    font-family: Montserrat, sans-serif;

    .nav-tabs {
      border: none;
      position: relative;
      overflow: hidden;
      margin: 0 auto;
      width: 100%;
      font-size: 14px;
      text-align: center;
    }
  }

  .wrapper-touch-select-placeholder {
    background: $grey-light;
    color: $grey-mid2;
    font-family: $font2;
    line-height: 34px;
    margin-bottom: 0;
  }

  .desktop-autosuggest {
    position: relative;
    .wrapper-touch-select-placeholder {
      border-radius: 4px;
    }
  }

  .wrapper-datetimepicker {
    margin-bottom: 10px;
    border-radius: 0;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .wrapper-date {
    .DateRangePicker {
      width: 100%;
    }
    .DateRangePickerInput {
      width: 100%;
      padding-right: 10px;
    }
    .DateInput {
      cursor: pointer;
      background: $pepe-grey;
      color: $grey-mid2;
      font-size: 13px;
      height: 34px;
      padding: 6px 12px;
      line-height: 1.42857143;
      background-image: none;
      transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
      border-radius: 0;
      margin-bottom: 10px;

      .DateInput_input {
        cursor: pointer;
        caret-color: transparent;
        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE3LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhcGFfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiCgkgd2lkdGg9IjEycHgiIGhlaWdodD0iNnB4IiB2aWV3Qm94PSIwIDAgMTIgNiIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTIgNiIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxwYXRoIGZpbGw9IiMwMTAxMDEiIGQ9Ik0wLDBMNS45OTksNkwxMiwwIi8+Cjwvc3ZnPgo=) no-repeat right center;
        font-size: 12px;
        border: 0;
        color: inherit;
        width: 100%;
        padding: unset;
        line-height: 1.42857143;

        &.DateInput_input__focused {
          @include placeholder {
            color: #007a87;
          }
        }
      }

      .DateInput__display-text--has-input {
        color: $grey-mid2;
        background: none;
      }
    }
  }

  .time-selector {
    &-container {
      position: relative;
      margin-bottom: 10px;
      padding: 0px 11px;
      display: flex;
      align-items: center;
      background-color: #f5f5f5;
      .time-selector-input{
        &:focus {
         box-shadow: none;
        }
      }
      .time-selector-popup {top:36px;}
      &.editing {
        box-sizing: border-box;
        box-shadow: inset 0px 0px 0px 3px rgba(37, 148, 206, 0.3);
      }

      input {
        background: transparent !important;
        // color: $grey-dark2 !important;
      }
    }
    &-layer {
      opacity: 0;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 1;
    }
    &-popup {
      z-index: 2;
      position: absolute;
      width: 105px;
      height: 193px;
      left: 0px;
      top: 72px;
      background: $white;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 12px;
      padding: 0px 4px 0px 11px;

      &__scrollable-area {
        height: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        padding-right: 12px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        scrollbar-color: #8B8B8B #fff;   /* Firefox - The first applies to the thumb of the scrollbar, the second to the track. */
        scrollbar-width: thin; /* Firefox */
        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
          width: 4px;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #8B8B8B;
          border-radius: 2px;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      }

      &__option { // each option
        display: flex;
        column-gap: 13px;
        align-items: center;
        width: 100%;
        text-align: right;
        justify-content: flex-end;
      }
      &__option-content { // content inside the option
        cursor: pointer;
        font-weight: 500;
        font-size: 16px;
        line-height: 32px;
        color: $grey-dark2;
        width: 40px;
        text-align: left;
        &.selected {
        }
      }
    }
  }
  .wrapper-date-picker {
    .DateRangePicker {
      width: 100%;
    }
    .DateRangePickerInput {
      width: 100%;
      padding-right: 10px;
    }
    .DateInput {
      cursor: pointer;
      background: $pepe-grey;
      color: $grey-mid2;
      font-size: 13px;
      height: 34px;
      padding: 6px 12px;
      line-height: 1.42857143;
      transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
      border-radius: 0;
      display: flex;
      align-items: center;
      column-gap: 12px;
      margin-bottom: 10px;

      &.editing {
        box-sizing: border-box;
        box-shadow: inset 0px 0px 0px 3px rgba(37, 148, 206, 0.3);
      }

      .DateInput_input {
        font-size: 12px;
        background: url('../../../static/img/icons/wrapper-touch-select-placeholder.svg') no-repeat right center;
        width: 100%;
        border: none;
        padding: 0;
        color: #9c9c9c;
        &.filled {
          // color: $pepe-black-text;
        }

        &.DateInput_input__focused {
          @include placeholder {
            color: #007a87;
          }
        }
      }

      .DateInput__display-text--has-input {
        color: $grey-mid2;
        background: none;
      }
    }
  }

  .customer-country {
    margin-top: 0;
  }

  .customer-country-label {
    padding-bottom: 5px;
  }
  .customer-country-input {
    width: 100%;
  }
}

.root-desktop .wrapper-search-box {
  .react-autosuggest__container {
    overflow: hidden;
    max-height: 250px;
    z-index: 99999;
    position: absolute;
    width: 100%;
    padding: 5px 8px;
    margin: 2px 0 0;
    font-size: 14px;
    background-color: white;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0,0,0,.175);

    input {
      background: $pepe-grey;
      color: $grey-mid2;
      font-size: 13px;
      width: 100%;
      border: none;
      height: 34px;
      padding: 6px 12px;
      line-height: 1.42857143;
      transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
      box-sizing: border-box;
    }

    .react-autosuggest__suggestions-container {
      overflow-y: auto;
      max-height: 200px;
      overflow-x: hidden;
      .react-autosuggest__suggestions-list {
        list-style: none;
        padding: 0;
        margin: 3px 0;
        li {
          padding: 3px 20px;
          line-height: 1.42857143;
          color: #333;
          cursor: pointer;
          &:hover {
            color: #262626;
            text-decoration: none;
            background-color: #f5f5f5;
          }
          .wrapper-suggestion {
            overflow: hidden;

            .wrapper-image {
              float: left;
              margin-right: 5px;

              img {
                max-width: 16px;
                height: auto;
              }
            }

            .wrapper-flag {
              float: left;
              margin-right: 5px;

              .flag-icon {
                width: 0.8em;
              }
            }
          }
        }
      }
      .react-autosuggest__section-container {
        .react-autosuggest__section-title {
          padding: 5px 15px;
          font-size: 0.85em;
          color: $grey-mid2;
          margin-top: 21px;
          position: relative;
          &::after {
            height: 1px;
            background-color: $pepe-mid-grey;
            top: -10px;
            content: " ";
            width: 100%;
            position: absolute;
          }
        }

        &.react-autosuggest__section-container--first {
          .react-autosuggest__section-title {
            margin-top: 10px;
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }

  .custom-autosuggest {
    input {
      background: $pepe-grey;
      color: $grey-mid2;
      font-size: 13px;
      width: 100%;
      border: none;
      height: 34px;
      padding: 6px 12px;
      line-height: 1.42857143;
      transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
      box-sizing: border-box;
    }
    .offices-popup-header {
      padding: 8px 16px 4px;
      font-weight: 700;
      font-size: 13px;
      line-height: 22px;
      text-align: right;
      letter-spacing: 0.46px;
      text-transform: uppercase;
      color: #D4D4D4;
      text-align: left;
    }
    .react-autosuggest {
      &__suggestions {
        &-container {
          position: absolute;
          top: 50px;
          z-index: 99999;
          width: 447px;
          max-height: 270px;
          overflow-y: scroll;
          background: #FFFFFF;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 12px;
        }
        &-list {
          list-style: none;
          padding: 0;
          margin: 3px 0;
          li {
            padding: 4px 16px;
            line-height: 1.42857143;
            color: #333;
            cursor: pointer;
            &:hover {
              color: #262626;
              text-decoration: none;
              background-color: #f5f5f5;
            }
            .wrapper-suggestion {
              overflow: hidden;
              align-items: center;
              display: flex;
              .wrapper-name {
                color: $grey-dark2;
                > span {
                  font-size: 14px;
                  line-height: 24px;
                  letter-spacing: 0.15px;
                }
                &-subtitle {
                  display: flex;
                  align-items: center;
                  > span {
                    font-size: 10px;
                    line-height: 14px;
                  }
                }
              }
              .wrapper-image {
                margin-right: 11.5px;
                display: flex;
                img {
                  width: 16px;
                }
              }

              .wrapper-flag {
                margin-right: 8px;
                width: 12px;
                height: 8px;
                .flag-icon {
                  width: 12px;
                  height: 8px;
                  display: block;
                  &::before{
                    content:'';
                  }
                }
              }
            }
          }
        }
      }
      &__section-container {
        &:last-of-type {
          margin-bottom: 10px;
        }
    }
      &__section-title {
        padding: 8px 16px 4px;
        position: relative;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 14px;
        color: $grey-dark2;
      }
    }
  }

  .react-autosuggest__container {
    overflow: hidden;
    max-height: 250px;
    z-index: 99999;
    position: absolute;
    width: 100%;
    padding: 5px 8px;
    margin: 2px 0 0;
    font-size: 14px;
    background-color: white;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
  }

  .predictive-location-input {
    width: 100%;
    position: relative;
    &__pickup.dual {
      .desktop-predictive {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
    }
    &__dropoff {
      .desktop-predictive {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
        border-left: 2.5px solid #D4D4D4;
      }
    }
    &.withRACSearchFormError {
      .desktop-predictive {
        // border: 3px solid rgba(251, 164, 83, 0.5);
        box-shadow: inset 0px 0px 0px 3px rgba(251, 164, 83, 0.5)
      }
      .error-message {
        display: block;
      }
    }

    .desktop-predictive {
      position: relative;
      .location-item-type {
        position: absolute;
        z-index: 2;
        top: 10px;
        left: 5px;
      }
      .react-autosuggest__input {
        padding-left: 30px;
        &:disabled { background: $pepe-dark-text; }
      }

      .react-autosuggest__container {
        position: relative;
        padding: 0;
        border: none;
        overflow: visible;
        z-index: auto;
        box-shadow: none;

        .react-autosuggest__suggestions-container {
          z-index: 9999;
          position: absolute;
          background: $white;
          width: 100%;
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
          display: none;

          &.react-autosuggest__suggestions-container--open {
            display: block;
          }
        }
      }
    }

    .desktop-predictive {
      margin-bottom: 0;
      position: relative;
      background-color: $white;
      border-radius: 12px;
      &.editing {
        box-shadow: inset 0px 0px 0px 3px rgba(37, 148, 206, 0.3) !important;
      }
      .location-item-type {
        // position: absolute;
        // z-index: 2;
        // top: 16px;
        // left: 8px;
        // width: 24px;
        // height: 24px;
        img {
          width: 100%;
        }
      }
      .custom-autosuggest {
        .react-autosuggest {
          &__container {
            position: relative;
            padding: 0;
            border: none;
            overflow: visible;
            z-index: auto;
            box-shadow: none;

          }
          &__suggestions-container {
            z-index: 9999;
            position: absolute;
            background: $white;
            display: none;

            width: 447px;
            max-height: 270px;
            top: 72px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 12px;

            &.react-autosuggest__suggestions-container--open {
              display: block;
            }
          }
        }
      }
      .custom-autosuggest:not(.old-input) {
        .react-autosuggest {
          &__input {
            padding-left: 40px;
            height: 56px;
            background-color: transparent;

            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: 0.15px;
            color: #3B3B3B;

            &::placeholder {
              color: #D4D4D4;
            }

            &:disabled { background: $pepe-dark-text; }
          }
        }
      }
      .custom-autosuggest.old-input {
        .react-autosuggest {
          &__suggestions-container {
            top: 40px;
          }
        }
      }
    }
    .error-message {
      display: none;
      width: 100%;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.4px;
      color: #FBA453;
      margin-left: 40px;
      position: absolute;
      top: 100%;
      left: 0;
    }
  }
}

.DateRangePicker_picker {
  z-index: 10;
}

// landing + desktop
#SearchForm--Variant_B .search-box-desktop.landing {
  padding: unset !important;
  background-image: url('../../../static/img/foto-portada-3.jpg') !important;
  max-height: 524px;
  max-width: 356px;

  #search-box {
    box-shadow: none;
    background: unset;
    background-color: unset;

    .tab-content {
      padding: 16px !important;
      #search-box-title {
        .SearchBoxTitle__Title {
          font-family: $font2 !important;
          font-style: normal;
          font-weight: 700;
          font-size: 24px !important;
          line-height: 133.4%;
          color: $white !important;
          text-transform: inherit !important;
          letter-spacing: unset !important;
          text-align: unset;
        }
      }
      form.search-box-form {
        padding-top: 16px;
        display: flex;
        flex-direction: column;
        .form-group {
          margin-bottom: 0;
        }
        .offices-selector-input {
          order: 0;

          &.withRACSearchFormError {
            margin-bottom: 20px;
          }
        }
        .search-box-form__same-office-cbox {
          order: 0;
        }
        .date-time-input {
          order: 1;

          &.withRACSearchFormError {
            margin-bottom: 20px;
            box-shadow: inset 0px 0px 0px 3px rgba(251, 164, 83, 0.5)
          }
        }
        .filled-button {
          order: 2;
        }
        .predictive-location-input {
          .desktop-predictive {
            .location-item-type {
              top: 16px;
              left: 18px;
            }
            .react-autosuggest__input {
              padding-left: 62px;
            }
          }
          &.dual {
            .desktop-predictive {
              border-bottom-left-radius: 0;
              border-top-right-radius: 12px;
              margin-bottom: 0;
              border-bottom: 0.5px solid #D4D4D4;
            }
            &.withRACSearchFormError {
              .error-message {
                top: 112px;
              }
            }
          }
          &.predictive-location-input__dropoff .desktop-predictive {
            border-bottom-left-radius: 12px !important;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-left: unset;
          }
        }
        .search-box-form__same-office-cbox {
          display: flex;
          align-items: center;
          grid-gap: 8px;
          gap: 8px;
          padding: 12px 0 12px 24px;
          margin-left: 8px;
          cursor: pointer;
          position: relative;
          & > * {
            margin: 0 !important;
          }
          input {
            position: absolute;
            opacity: 0;
            height: 0;
            width: 0;
            &:checked ~ label .checkmark:after {
              display: block;
            }
          }
          .checkmark {
            position: absolute;
            top: 13px;
            left: 0;
            height: 16px;
            width: 16px;
            background-color: white;
            border-radius: 2px;
            &:after {
              content: "";
              position: absolute;
              display: none;
              left: 5px;
              top: 0px;
              width: 6px;
              height: 13px;
              border: solid black;
              border-width: 0 1px 1px 0;
              -webkit-transform: rotate(42deg);
              -ms-transform: rotate(42deg);
              transform: rotate(42deg);
            }
          }
          label {
            cursor: pointer;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.4px;
            color: #D4D4D4;
          }
        }
        .date-time-input {
          flex-wrap: wrap;
          .date-selector {
            flex-grow: 1;
            width: unset;
            padding-left: 18px;
            gap: 18px;
            &.date-selector-dropoff {
              border-left: none;
            }
          }
          .time-selector {
            width: unset;
            padding-left: 18px;
            gap: 18px;

            input {
              max-width: 59px;
            }
          }
          &.withRACSearchFormError {
            .date-selector-pickup,
            .date-selector-dropoff {
              box-shadow: none;
            }
          }
        }
        .filled-button {
          background: #D6322C;
          border: unset;
          border-radius: 3px;
          padding: 15px;
          font-weight: 700;
          font-size: 15px;
          line-height: 26px;
          text-align: center;
          letter-spacing: 0.46px;
          text-transform: uppercase;
          color: #FFFFFF;
        }
        .age-selector-container {
          .age-selector-dropdown {
            right: unset;
            left: 0;
          }
        }
        .country-selector-container {
          .country-selector-modal {
            left: 0;
          }
        }
        .error-message {
          margin-left: 12px;
        }
      }
    }
  }
}

// Landing: destinos => desktop
.landing.destinos #SearchForm--Variant_B {
  .search-box-desktop.landing {
    width: 350px;
    #search-box {
      top: 0;
      left: 0;
    }
  }
}

// Landing: destinos => mobile
.root-mobile .landing.destinos {
  #intro {
    padding: 0;
    height: auto;
    margin-bottom: 0;

    .container {
      height: auto;
    }

    #SearchForm--Variant_B {
      margin-bottom: 0;

      #search-box {
        position: static;
      }
    }
  }
}
