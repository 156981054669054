@import "checkout-sass-variables";

@import "./form/insurance/insurance-popup/component";

.checkout-step-desktop {
  width: $desktop-size;
  margin: 0 auto;
  .checkout-left-field-column  {
    padding-right: 10px !important;
  }
  .checkout-right-field-column {
    padding-left: 10px !important;
  }
}

.checkout-submit-button {
  height: 100%;
  background-color: $pepe-red;
  padding: 0 40px;
  color: white;
  font-size: 1.25em;
  letter-spacing: 6px;
  text-transform: uppercase;
  font-weight: bold;
  font-family: $font4;
  display: inline-block;
  line-height: 55px;
  cursor: pointer;
  &.full-width {
    width: 100%;
    text-align: center;
  }
  &:hover {
    color: white;
  }
}

.checkout-step {
  font-family: $font5;

  @import "./form/insurance/insurance";
  @import "./car-resume/car-resume";
  @import "./form/extras/extras";
  @import "./form/submit-wrapper/submit-wrapper";

  .checkout-main-price {
    font-size: 1.64em;
    font-family: $font4;
    color: $pepe-red;
    text-transform: uppercase;
  }

  .checkout-step-title {
    font-size: 2.28em;
    color: $pepe-red;
    text-transform: uppercase;
    font-family: $font;
  }

  .checkout-step-subtitle,
  .checkout-section-title,
  .checkout-pay-now{
    color: $pepe-black-text;
    font-size: 1.25em;
    letter-spacing: 6px;
    text-transform: uppercase;
    font-weight: bold;
    font-family: $font2;
  }

  .checkout-step-subtitle {
    margin-bottom: 35px;
  }

  .checkout-section-title {
    margin: 35px 0;
    .checkout-section-title-detail {
      font-family: $font2;
      font-size: 0.9em;
      font-weight: normal;
      text-transform: initial;
    }
  }

  .checkout-extras-terms {
    margin: 10px 0;
    .input-wrap {
      position: relative;
      margin: 0 auto 1em;
      display: inline-block;
      vertical-align: top;
      width: 370px;
      height: 80px;
    }
    .input-wrap--small {
      width: 20px;
      height: 20px;
      float: left;
      margin-right: 10px;
      margin-top: 2px;
    }
    .input-wrap input[type="text"] {
      display: block;
      width: 94.6%;
      height: 75%;
      border: none;
      position: relative;
      background: none;
      z-index: 100;
      outline: none;
      font-size: 1.5em;
      font-weight: bold;
      color: #aaa;
      left: 2.7%;
      top: 12.5%;
      padding: 0 0.75em;
      -webkit-tap-highlight-color: transparent;
      -webkit-tap-highlight-color: transparent;
      /* For some Androids */
    }
    .input-wrap input[type="checkbox"],
    .input-wrap input[type="radio"],
    .input-wrap .fa {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 100;
      opacity: 0;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
      -webkit-tap-highlight-color: transparent;
      /* For some Androids */
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    .input-wrap .fa {
      z-index: 99;
      line-height: 20px;
      color: #CC0000;
      left: 1px;
    }
    .input-wrap input[type="checkbox"]:checked + .fa,
    .input-wrap input[type="radio"]:checked + .fa {
      opacity: 1;
    }
    .morph-shape {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      pointer-events: none;
    }
    .morph-shape svg {
      border: 2px solid $pepe-black-text;
    }
    .morph-shape svg path {
      fill: transparent;
    }
    .input-label--long {
      width: calc(100% - 30px);
      cursor: pointer;
    }
  }
}

.checkout-step-1 {
  position: relative;
  .checkout-comodo-secure-banner {
    background: url("../../../static/img/icons/comodo-siteseal.png") no-repeat center;
    width: 86px;
    height: 56px;
    background-size: cover;
    position: absolute;
    right: 80px;
    top: 5px;
  }
  .form-section-wrapper {
    margin-bottom: 25px;
  }

  .desktop-autosuggest {
    .wrapper-touch-select-placeholder {
      line-height: 18px;
      margin-bottom: 0;
      cursor: pointer;
      border-radius: 0;
      .inner {
        background: url('../../../static/img/icons/wrapper-touch-select-placeholder.svg') no-repeat right center;
        background-size: 15px auto;
      }
    }
    .react-autosuggest__container {
      overflow: hidden;
      max-height: 250px;
      z-index: 99999;
      position: absolute;
      left: 0;
      top: 58px;
      width: 100%;
      padding: 5px 8px;
      margin: 2px 0 0;
      font-size: 14px;
      background-color: white;
      background-clip: padding-box;
      border: 1px solid rgba(0,0,0,.15);
      border-radius: 4px;
      box-shadow: 0 6px 12px rgba(0,0,0,.175);

      input {
        background: $pepe-grey;
        color: $grey-mid2;
        font-size: 13px;
        width: 100%;
        border: none;
        height: 34px;
        padding: 6px 12px;
        line-height: 1.42857143;
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        box-sizing: border-box;
      }

      .react-autosuggest__suggestions-container {
        overflow-y: auto;
        max-height: 200px;
        overflow-x: hidden;
        .react-autosuggest__suggestions-list {
          list-style: none;
          padding: 0;
          margin: 3px 0;
          li {
            font-family: $font2;
            padding: 3px 20px;
            line-height: 1.42857143;
            color: #333;
            cursor: pointer;
            &:hover {
              color: #262626;
              text-decoration: none;
              background-color: #f5f5f5;
            }
            .wrapper-suggestion {
              overflow: hidden;
              .wrapper-image {
                float: left;
                margin-right: 5px;
                img {
                  max-width: 16px;
                  height: auto;
                }
              }
            }
          }
        }
        .react-autosuggest__section-container {
          .react-autosuggest__section-title {
            padding: 5px 15px;
            font-size: 0.85em;
            color: $grey-mid2;
            margin-top: 21px;
            position: relative;
            &::after {
              height: 1px;
              background-color: $pepe-mid-grey;
              top: -10px;
              content: " ";
              width: 100%;
              position: absolute;
            }
          }

          &.react-autosuggest__section-container--first {
            .react-autosuggest__section-title {
              margin-top: 10px;
              &::after {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  input, select, .checkout-extras-terms {
    font-family: $font2;
  }

  .submit-wrapper {
    width: $desktop-size - $desktop-padding*2;
  }

  .checkout-form-wrapper {
    padding-bottom: 150px;
    .cs-skin-border {
      font-family: $font5;
    }
    .form-label {
      font-family: $font2;
      text-transform: uppercase;
      margin: 20px 0;
    }
    .form-control {
      background: 0 0;
      border: 1px solid #333;
      color: #333;
      font-size: 0.85em;
      padding: 20px;
      height: auto;
      text-transform: uppercase;
      width: 100%;
      margin-bottom: 20px;
      letter-spacing: 1px;
      -moz-appearance: none;
      -o-appearance: none;
      text-indent: 0.01px;
      text-overflow: '';
      &::-ms-expand {
        display: none;
      }
      &::-webkit-input-placeholder { /* WebKit browsers */
        color: #333;
      }
      &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: #333;
        opacity:  1;
      }
      &::-moz-placeholder { /* Mozilla Firefox 19+ */
        color: #333;
        opacity:  1;
      }
      &:-ms-input-placeholder { /* Internet Explorer 10+ */
        color: #333;
      }
      &:focus {
        &::-webkit-input-placeholder { /* WebKit browsers */
          transition: all 0.15s;
          opacity: 0.3;
        }
        &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
          transition: all 0.15s;
          opacity: 0.3;
        }
        &::-moz-placeholder { /* Mozilla Firefox 19+ */
          transition: all 0.15s;
          opacity: 0.3;
        }
        &:-ms-input-placeholder { /* Internet Explorer 10+ */
          transition: all 0.15s;
          opacity: 0.3;
        }
      }
    }

    select.form-control {
      cursor: pointer;
      height: 60px;
      -webkit-appearance: none;
      background: url('../../../static/img/icons/wrapper-touch-select-placeholder.svg') no-repeat right 10px center;
      background-size: 15px 7px;
    }

    .checkout-field {
      padding-right: 20px !important;
      &:last-of-type {
        padding-right: 0 !important;
      }
    }
  }

  .checkout-extras-wrapper {
    .panel-group {
      margin-bottom: 0 !important;
    }
    .panel {
      border: 1px solid;
      border-radius: 0px;
      //Overlap borders with negative margin
      margin-top: -1px !important;
    }
  }

  /* Legacy Css */
  .invalid {
    border: 1px solid red !important;

  }

  .invalid.cs-skin-border > span {
    border: none !important;
  }
}

#checkout.payment-step-1 {
  .checkout-input-filled {
    background-color: $white;
  }
  .btn-discount-active {
    background-color: #C00;
    color: white;
  }
  select {
    background: url('../../../static/img/icons/wrapper-touch-select-placeholder.svg') no-repeat right 10px center;
    background-size: 15px 7px;
  }
}
