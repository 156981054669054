
.wrapper-car-list {
  display: flex;
  flex-direction: column;

  .no-results {
    border: 1px solid $pepe-red;
    border-radius: 10px;
    font-size: 1.2em;
    padding: 10px;
  }
}

.wrapper-car {
  position: relative;
  box-sizing: border-box;
  border: 1px solid $pepe-dark-grey;

  .wrapper-layout {
    overflow: hidden;
    position: relative;
    display: flex;

    .wrapper-tabs {
      height: 100%;
      flex: 1 1 auto;
    }
    .wrapper-tab-selector {
      height: 100%;
      flex: 0 0 auto;
    }

    .wrapper-tabs .wrapper-tab-active {
      overflow-y: auto;
      &.wrapper-tab-active-tab-0 { overflow-y: hidden; }

      .inner-tab {
        position: relative;

        .toggler .icon {
          display: block;
          content: '';
          position: absolute;
        }

        .toggler {
          border-bottom: 1px solid $pepe-dark-grey;
        }
        .content {
          display: none;
          border-bottom: 1px solid $pepe-dark-grey;
        }

        .visible-active { display: none; }

        &:last-child {
          .content { border-bottom: 0; }
        }

        &.active {
          .toggler .icon {
            background-image: url('../../../../../static/img/icons/tab-open.svg');
          }
          .toggler { border-bottom: 0; }
          .content { display: block; }

          .visible-active { display: block; }
          .hidden-active { display: none; }
        }

        .toggler .visible-active,
        .toggler > .inner-tab-title {
          font-size: 0.8em;
          line-height: 1em;
        }
      }
    }
  }
}
