.wrapper-car .wrapper-layout .wrapper-tabs .wrapper-tab-active .wrapper-tab-taxes {
  font-family: "Montserrat", sans-serif;
  font-size: 0.6em;
  .group {
    .title {
      font-size: 0.9em;
      font-weight: bold;
    }
    .content {
      color: $pepe-dark-text;
      font-size: 0.9em;
    }
  }
}
