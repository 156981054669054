.wrapper-car .wrapper-tab-selector {
  background: $pepe-mid-grey;
  margin: 0;
  padding: 0;

  li {
    padding: 0;
    border-bottom: 1px solid $pepe-dark-grey;
    box-sizing: border-box;
    border-left: 3px solid $pepe-red;

    &.active {
      background: $white;
      border-right: 3px solid $pepe-red;
      border-left: 0;
    }


    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;

    span {
      text-transform: uppercase;
      text-align: center;

      font-size: 0.3em;
      //font-weight: bold;
      line-height: 1.4em;

      font-family: $font2;

      background: url('https://dummyimage.com/50x40/ddd/000') no-repeat top center;
    }

    $list: 'info', 'extras', 'taxes', 'terms', 'summary';
    @each $key in $list {
      &.tab-#{$key} span {
        background-image: url('../../../../../static/img/icons/tabs/#{$key}.svg');
      }
    }
  }
}
