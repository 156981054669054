
@media (orientation: landscape) {
  @import "./landscape/variables";
  @import "./landscape/header";
}

@media (orientation: portrait) {
  @import "./portrait/variables";
  @import "./portrait/header";
}
