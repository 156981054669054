.checkout-step-mobile {
  width: 100%;
}

@import "../checkout-step-1/component";

.checkout-step {
  .checkout-step-title {
    margin-top: 0;
    margin-bottom: 5px;
  }

  .checkout-section-title {
    margin: 15px 0;
  }

  .price-blocks-container {
    margin-bottom: 15px;
  }

  .price-block-wrapper {
    .img-wrapper img {
      max-width: 100%;
    }
    .prices-wrapper {
      padding-left: 20px;

      .price {
        width: 100%;
        margin-bottom: 15px;
        &:last-child { margin-bottom: 0; }
      }
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}


.checkout-step-mobile {
  .wrapper-current-cc-data {
    margin-bottom: 50px;
    .payment-card-label {
      display: block;
    }
    .payment-card-actions {
      .btn {
        margin: 0;
        margin-bottom: 15px;
        width: 100%;
      }
    }
  }
}

.checkout-step-mobile {
  .wrapper-remember-booking-user-data {
    margin-bottom: 50px;
  }
}

.checkout-step-mobile.checkout-step-1 {
  .checkout-input-filled {
    background-color: $white;
  }
  .btn-discount-active {
    background-color: #C00;
    color: white;
  }
  select {
    background: url('../../../static/img/icons/wrapper-touch-select-placeholder.svg') no-repeat right 10px center;
    background-size: 15px 7px;
    /* For some Androids */
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}
