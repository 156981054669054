.wrapper-row-characteristics {
  height: $row-characteristics-height;
  text-align: center;
  padding: 20px 10px;

  .inner-vertical-center {
    width: 110%;
    right: get-size(10px);
  }

  .box-feature-list {
    display: inline-block;
    margin: 0 auto;
  }
}
