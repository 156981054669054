@import "./form/component";
@import "../../desktop/checkout/form/insurance/insurance-popup/component";

.checkout-step-1 .checkout-form-wrapper {
  padding-bottom: 0px;

  .form-control {
    margin-bottom: 12px;
    font-size: 0.8em;
    padding: 20px 10px;
  }
}
