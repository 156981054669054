@import "../pepe-theme-sass-variables";

.react-datepicker {
  font-family: $font;
  font-size: 14px;
  border: 0;
}

.react-datepicker__header {
  border: 0;
  padding: 0;
}

.react-datepicker__portal.react-datepicker__portal-with-error .react-datepicker {
  border: 4px solid #FBA453
}

.react-datepicker__portal .react-datepicker__current-month {
  font-size: 18px;
  line-height: 30px;
  color: $white;
  background-color: $pepe-black-text;
  text-transform: uppercase;
  padding: 0 13px;
}

.react-datepicker__day-names {
  font-size: 16.8px;
  background-color: #868686;
  text-transform: uppercase;
  height: 30px;
  padding: 0 13px;
  margin: 0;

  .react-datepicker__day-name {
    color: $white;
    line-height: 30px;
    margin: 0;
    width: 40px;
  }
}

.react-datepicker__month {
  margin: 0;
  padding: 10px 13px 20px 13px;
}

.react-datepicker__portal .react-datepicker__day {
  color: $grey-dark;
  font-size: 16.8px;
  width: 40px;
  line-height: 40px;
  margin: 0;
  border-radius: 0;

  &.react-datepicker__day--keyboard-selected,
  &:hover {
    color: $white;
    background-color: $pepe-red;
  }
}

.react-datepicker__portal .react-datepicker__day--today {
  font-weight: normal;
}

.react-datepicker__portal .react-datepicker__day--disabled {
  color: $pepe-dark-grey2;
}

$lighten: 0%;
$darken: 10%;

.react-datepicker__portal .react-datepicker__day--in-range {
  color: $white;
  background-color: $pepe-red;

  &.react-datepicker__day--range-start,
  &.react-datepicker__day--range-end {
    background-color: darken($pepe-red, $darken);
  }
}

.react-datepicker__portal .react-datepicker__day--in-selecting-range {
  color: $white;
  background-color: $pepe-red;

  &.react-datepicker__day--selecting-range-start,
  &.react-datepicker__day--selecting-range-end {
    background-color: darken($pepe-red, $darken);
  }
}

.date-time-input,
.wrapper-date-picker {

  .react-datepicker__navigation {
    position: absolute;
    top: -2px;
    span {
      width: 28px;
      height: 28px;
    };
    &--next {
      right: 77px;
    }
    &--previous {
      left: 77px;
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .react-datepicker__custom-header {
    position: absolute;
    width: 100%;
    top: 16px;
    left: 0;
    h4 { 
      text-align: center;
      font-weight: bold;
      font-size: 13px;
      line-height: 22px;
      letter-spacing: 0.46px !important;
      text-transform: uppercase;
      color: #D4D4D4;
    }
    &-error {
      color: #FBA453 !important;
      display: flex;
      justify-content: center;
      gap: 10px;
    }
  }
  .react-datepicker__custom-footer {
    position: absolute;
    width: 100%;
    bottom: 8px;
    left: 0;
    text-align: center;
    span {
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.4px;
      color: $grey-dark2;
    }
  }

  .react-datepicker {
    font-family: $font2;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;

    padding: 62px 24px 34px;
    border: 0;
    border-radius: 12px;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    display: flex;
    column-gap: 32px;

    height: 382px;
  }

  .react-datepicker__header {
    border: 0;
    padding: 0;
  }

  .react-datepicker__portal {
    background-color: rgba(59, 59, 59, 0.8);
    backdrop-filter: blur(4px);
  }

  .react-datepicker__month-container {

  }

  .react-datepicker__portal .react-datepicker__current-month {
    display: flex;
    justify-content: center;
    align-content: center;
    background-color: $white;
    padding: 0 0 4px;

    p {
      font-weight: normal;
      font-size: 12px;
      height: 24px;
      letter-spacing: 0.12px;
      color: #3B3B3B;
      text-transform: capitalize;
      padding: unset;
      margin: 0;
    }
  }

  .react-datepicker__month {
    margin: 0;
    padding: unset;
    display: flex;
    flex-direction: column;
    // gap: 8px;
  }

  .react-datepicker__day-names {

    background-color: $white;
    margin: 0;
    padding: unset;
    height: 16px;
    display: flex;
    
    .react-datepicker__day-name {
      color: #BCBCBC;
      margin: 0;
      width: 40px;

      font-weight: bold;
      font-size: 10px;
      line-height: 16px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 1.5px;
      text-transform: uppercase;
    }
  }
  
  .react-datepicker__week {
    display: flex;
  }

  .react-datepicker__portal .react-datepicker__day {
    color: #3B3B3B;
    font-size: 16.8px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    padding: 4px 0;
    margin: 0;
    background-color: unset;
    &.react-datepicker__day--outside-month {
      color: transparent;
      background-color: inherit;
      height: 0px;
    }
    &.react-datepicker__day--disabled {
      color: #EDEDED;
    }

    .react-datepicker__day--content {
      height: 100%;
      width: 100%;
    }
    .react-datepicker__day--inner{
      font-weight: 500;
      font-size: 14px;
      height: 32px;
      width: 32px;
      line-height: 32px;
    }
    
    &.react-datepicker__day--keyboard-selected,
    &:hover {
      border-radius: 0 50% 50% 0;
      color: $white;
      &.react-datepicker__day--in-selecting-range .react-datepicker__day--content {
        background: linear-gradient(90deg, #F4CAC8FF 0%, #F4CAC800 50%);
      }
      
      .react-datepicker__day--inner{
        background-color: #D6322C;
      }
    }

    .react-datepicker__day--inner{
      border-radius: 50% !important;
    }
  }

  .react-datepicker__portal .react-datepicker__day--today {
    font-weight: normal;
  }

  .react-datepicker__portal .react-datepicker__day--in-range {

    &.react-datepicker__day--outside-month {
      &.react-datepicker__day--001{
        background: linear-gradient(90deg, #F4CAC8FF 0%, #F4CAC800 75%);
        margin: 4px 0;
        height: 32px;
      }
      .react-datepicker__day--content { 
        background: inherit !important; 
      }
      &.react-datepicker__day--range-end {
        background: inherit !important;
      }
      &.react-datepicker__day--range-start {
        background: inherit !important;
      }
    }
    &.outside-month-previous {
        background: linear-gradient(90deg, #F4CAC800 25%, #F4CAC8FF 100%);
        margin: 4px 0;
        height: 32px;
    }

    &:hover {
      border-radius: 0;
    }
    
    .react-datepicker__day--content {
      background-color: #F4CAC8;
      border-radius: 0;
    }
    &:first-of-type {
      .react-datepicker__day--content {
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
      }
    }
    
    &:last-of-type {
      .react-datepicker__day--content {
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
      }
    }
    
    &.react-datepicker__day--range-start .react-datepicker__day--content {
      border-top-left-radius:  16px;
      border-bottom-left-radius:  16px;
      color: $white;

      .react-datepicker__day--inner {
        background-color: #D6322C;
        border-radius: 50%;
      }
    }

    &.react-datepicker__day--range-end {
      
      color: $white;
      .react-datepicker__day--content {
        background: linear-gradient(90deg, #F4CAC8FF 0%, #F4CAC800 62%);

        .react-datepicker__day--inner {
          background-color: #D6322C;
          border-radius: 50%;
        }
      }
    }
  }

  .react-datepicker__portal .react-datepicker__day--in-selecting-range {
    .react-datepicker__day--content {
      background-color: #F4CAC8;
      border-radius: 0;
    }

    &:first-of-type {
      .react-datepicker__day--content {
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
      }
    }

    &:last-of-type {
      .react-datepicker__day--content {
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
      }
    }
    
    &.react-datepicker__day--selecting-range-start {
      .react-datepicker__day--content {

        border-top-left-radius:  16px;
        border-bottom-left-radius:  16px;
        color: $white;
        
        .react-datepicker__day--inner {
          background-color: #D6322C;
        }
      }
    }

    &.react-datepicker__day--selecting-range-end {
      .react-datepicker__day--content {
        border-top-right-radius:  16px;
        border-bottom-right-radius:  16px;
        color: $white;
        
        .react-datepicker__day--inner {
          background-color: #D6322C;
        }
      }
    }
  }
}