@import "../../../../sass/pepe-theme-sass-variables";

@import "./common/tabs";
@import "./common/tabs/info";
@import "./common/tabs/extras";
@import "./common/tabs/terms";
@import "./common/tabs/taxes";
@import "./common/tabs/summary";

@media (orientation: landscape) {
  @import "./landscape/variables";
  @import "./landscape/tabs";
  @import "./landscape/tabs/info";
  @import "./landscape/tabs/extras";
  @import "./landscape/tabs/terms";
  @import "./landscape/tabs/summary";
  @import "./landscape/tabs/taxes";
}

@media (orientation: portrait) {
  @import "./portrait/variables";
  @import "./portrait/tabs";
  @import "./portrait/tabs/info";
  @import "./portrait/tabs/extras";
  @import "./portrait/tabs/terms";
  @import "./portrait/tabs/summary";
  @import "./portrait/tabs/taxes";
}
