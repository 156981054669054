.price-block-wrapper {
  background: white;
  padding: 30px 25px;
  margin-bottom: 10px;
  .prices-wrapper {
    padding-left: 20px;
  }

  .price {
    > .title {
      font-weight: bold;
      color: $pepe-black-text;
    }
  }
}

.payment-resume-box {
  width: 370px;
  color: $pepe-black-text;
  box-sizing: border-box;
  margin-left: 60px;
  padding-left: 0px;

  .total-price {
    font-family: $font3;
    font-size: 12px;
    letter-spacing: -0.5px;
    font-weight: 500;
    .amount {
      padding-left: 10px;
      font-weight: bold;
      font-size: 16px;
      letter-spacing: 0;
    }
  }
  .price-box-wrapper {
    margin-bottom: 30px;
    .price-box-wrapper-title {
      font-family: $font2;
      font-size: 20px;
      letter-spacing: 0;
      padding-bottom: 10px;
    }
    .price-box-detail-wrapper {
      background: $white;
      padding: 20px 30px;
      margin-top: 2px;
      overflow: hidden;
      .price-box-detail-left {
        img {
          max-width: 90px;
          width: auto;
        }
      }
      .price-box-detail-right {
        //float: right;
      }
    }
    .price-box-detail {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .price-box-detail-amount {
      font-family: $font3;
      font-size: 36px;
      color: $pepe-red;
      letter-spacing: 0;
      text-align: right;
      font-weight: bold;
    }
    .price-box-detail-taxes {
      font-family: $font3;
      font-size: 12px;
      color: $grey-mid;
      letter-spacing: 0;
      text-align: right;
    }
    .price-box-subtitle {
      font-family: $font3;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
    }
    .price-box-rates-subtitle-wrapper {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      margin-bottom: 15px;
    }
    .price-box-float-left {
      float: left;
    }
    .price-box-float-right {
      float: right;
    }
    .price-block-extra-line-item {
      list-style: none;
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }
    .price-box-rates-description-advise {
      font-family: $font3;
      font-size: 12px;
      color: $grey-mid;
      letter-spacing: 0;
      margin-bottom: 15px;
    }
    .price-box-rates-amount {
      font-family: $font3;
      font-size: 16px;
      letter-spacing: 0;
      text-align: right;
      font-weight: bold;
    }
    .margin-top-25 {
      margin-top: 25px;
    }
    .price-box-rates-description-text {
      font-family: $font3;
      font-size: 12px;
      letter-spacing: -0.1px;
      line-height: 14px;
      p {
        display: inline;
      }
      .price-box-view-more-link {
        color: $grey-mid;
        text-decoration: underline;
        margin-left: 3px;
      }
      .hide-more-information {
        margin-top: 15px;
        .price-box-view-more-link {
          margin-left: 0;
        }
      }
    }

    .price-box-extra-wrapper {
      .price-box-subtitle {
        margin-bottom: 15px;
      }
    }
    .price-box-extra-line-items-list {
      margin: 0;
      padding: 0;
      .price-block-extra-line-item {
        margin-bottom: 10px;
        &:last-of-type {
          margin-bottom: 0;
        }
        .price-block-extra-line-item-description {
          font-family: $font3;
          font-weight: 500;
          font-size: 12px;
          color: #4A4A4A;
          letter-spacing: 0;
          padding-right: 20px;
        }
      }
      &.rates-list {
        margin-top: 15px;
        .price-box-rates-amount {
          font-size: 13px;
        }
      }
    }
  }
}

.checkout-step-mobile {
  .payment-resume-box {
    width: 100%;
    margin-left: 0;
    margin-bottom: 40px;

    .price-box-wrapper {
      .price-box-detail-wrapper {
        padding: 20px;
        margin-top: 1px;
      }
      .price-box-detail-left {
        width: 65px;
        img {
          width: 100%;
          height: auto;
        }
      }
      .price-box-detail-amount {
        font-size: 32px;
        margin-bottom: 5px;
      }
      .collapse-price-box-charges {
        .collapser {
          padding: 10px 20px;
          padding-left: 35px;
          font-size: 12px;
          line-height: 12px;
          color: $pepe-black-text;
          font-weight: 600;
          letter-spacing: 0;
          &.collapsed {
            background: url('../../../static/img/checkout/charges-collapsed.svg') no-repeat 20px center $grey-light2;
          }
          &.expanded {
            background: url('../../../static/img/checkout/charges-expanded.svg') no-repeat 20px center $grey-light2;
          }
        }
        .price-box-detail-wrapper {
          background: $grey-light2;
        }
      }
    }
    .no-margin-bottom{
      margin-bottom: 0;
    }
  }
}
