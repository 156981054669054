/* Colors */
$pepe-red: #C00;
$pepe-grey: #f3f3f3;
$pepe-mid-grey: #ececec;
$pepe-dark-grey: #DDD;
$pepe-dark-grey2: #c7c5c8;
$pepe-dark-grey-3: #979797;
$pepe-dark-text: #868686;
$pepe-black-text: #333;

// VARIABLES
$mobile: max-width 767px;
$tablet: 768px 1199px;
$desktop: min-width 1160px;
$no-mobile: min-width 768px;
$no-desktop: max-width 1159px;
$retina: 2 device-pixel-ratio;

// COLORS
$black: #191918;
$grey: #dddddd;
$grey-dark: #414141;
$grey-dark2: #3B3B3B;
$grey-light: #f5f5f5;
$grey-light2: #f9f9f9;
$grey-mid: #7a7a7a;
$grey-mid2: #9c9c9c;
$white: #ffffff;
$green: #59a819;
$orange: #F5A623;
$red: #cc0000;
$blue: #5195c6;

$insurance-blue: #E5F1F8;
$insurance-blue-text: #009EE2;

// FONTS
$font: 'Franklin Gothic', sans-serif;
$font2: "Montserrat", sans-serif;
$font3: "Helvetica Neue", Helvetica, Arial, sans-serif;
$font4: "Franklin Gothic Demi", sans-serif;
$font5: "Franklin Gothic Book", sans-serif;
