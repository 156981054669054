$car-feature-height: get-size(30px);

.car-feature-label {
  min-width: get-size(160px);
  display: inline-block;
  height: $car-feature-height;
  border-radius: get-size(12px);
  padding-left: get-size(15px);
  padding-right: get-size(30px);
  margin-bottom: get-size(5px);
  margin-right: get-size(15px);
  border: 1px solid $pepe-dark-grey;
  background-color: white;
  position: relative;
  overflow: hidden;
  font-family: $font2;
  font-size: get-size(12px);
  color: $black;

  .car-feature-name {
    line-height: 1em;
  }
  .car-feature-detail {
    font-size: 0.75em;
  }

  .feature-label-icon {
    position: absolute;
    right: 0;
    top:0;
    height: 100%;
    width: $car-feature-height - 2px;
    padding: get-size(4px);

    .pepe-icon {
      width: 100%;
      height: 100%;
    }
  }
}
