.car-result-box .wrapper-price {
  .results-prices {
    .previous-price {
      font-size: 0.85em;
      margin-bottom: 0;
    }
    .tax-included {
      margin: 0 !important;
      font-size: 0.6em;
    }
  }
  .booking-nav-options {
    .box-terms {
      height: get-size(28px);
      .terms-button {
        line-height: get-size(26px);
        vertical-align: top;
        color: $pepe-dark-text !important;
      }
    }
    .wrapper-toggler-includes {
      height: get-size(28px);
      .pepe-detail-text {
        line-height: get-size(26px);
      }

      .pepe-nav-button {
        width: get-size(28px);
        height: get-size(28px);
        padding: get-size(8px);
      }
      .toggler-includes-open {
        position: absolute;
        right: -1px;
        top: -1px;
      }
    }
  }
}

.car-result-box .wrapper-search-includes .inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
}
