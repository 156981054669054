$pepe-grey: #f3f3f3;
$pepe-mid-grey: #ececec;
$pepe-dark-grey: #DDD;
$pepe-dark-grey2: #c7c5c8;
$pepe-dark-grey-3: #979797;
$pepe-dark-text: #868686;
$pepe-black-text: #333;
$grey-dark: #414141;
$white: #FFF;

.checkout-car-extras-box-mobile {
  background-color: $pepe-grey;
  .panel-body {
    padding: 0;
  }
  .checkout-car-extras-extra {
    border-bottom: 1px solid $pepe-dark-grey;
  }
  .checkout-car-extras-heading,
  .checkout-car-extras-extra {
    background-color: $pepe-mid-grey;
    padding: 20px 25px;
    padding-right: 53px;
    position: relative;
    .checkout-car-extras-panel-display {
      display: block;
      &:after {
        content: "";
        position: absolute;
        width: 15px;
        height: 8px;
        top: 50%;
        right: 20px;
        background: url('../../../../../static/img/icons/wrapper-touch-select-placeholder.svg') no-repeat right center;
        background-size: 15px auto;
        transform: rotate(180deg);
      }
      &.collapsed:after {
        /* rotate "arrow-down" */
        transform: rotate(0deg);
      }
    }
    .checkout-car-extras-description,
    .checkout-car-extras-price {
      display: inline-block;
      height: 55px;
      line-height: 55px;
      text-align: center;
    }
    .checkout-car-extras-description-wrapper {
      width: 70%;
      padding-right: 1em;
      display: inline-block;
      .checkout-car-extras-description {
        width: 100%;
        font-size: 0.85em;
        margin-right: 20px;
        display: inline-block;
        background-color: $pepe-dark-grey;
      }
      .baby-seat-selector-wrapper {
        height: 30px;
        line-height: 30px;
        background: none;
        .checkout-car-extras-extra-price-description-minus,
        .checkout-car-extras-extra-price-description-plus {
          width: 30px;
          height: 30px;
        }
      }
    }
    .checkout-car-extras-price {
      width: 30%;
      background-color: white;
    }
    .checkout-car-extras-description-box {
      display: inline-block;
      margin-bottom: 1em;
      .car-info-bubble {
        float: left;
        top: 3px;
      }
      .checkout-car-extra-description-text {
        display: inline-block;
        width: calc(100% - 4em);
      }
    }
  }
  .checkout-car-extras-body {
    overflow: hidden;
    transition: height ease-in-out .15s;
  }
  .checkout-car-extras-extra {
    padding-right: 25px;
  }
  .included-extra {
    .checkout-car-extras-price {
      width: 100%;
    }
  }
  .checkout-car-extras-extra-price-label {
    display: inline-block;
    float: right;
    line-height: 30px;
    padding-right: 20px;
  }
  .checkout-car-extras-extra-price-checkbox {
    display: inline-block;
    border: 1px solid $pepe-dark-grey-3;
    width: 30px;
    height: 30px;
    background: $white;
    position: relative;
    float: right;
    &.selected:after {
      content: '';
      background: url('../../../../../static/img/checkout/pepecar-extra-selected.png') no-repeat center;
      width: 29px;
      height: 29px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}




.checkout-car-extras-extra {
  .checkout-car-extras-extra-price-description-checkbox,
  .checkout-car-extras-extra-price-description-checkbox-included,
  .checkout-car-extras-extra-price-description-minus,
  .checkout-car-extras-extra-price-description-plus,
  .checkout-car-extras-extra-price-description-tick {
    background-color: $pepe-black-text;
    color: #fff;
    width: 55px;
  }

  .checkout-car-extras-extra-price-description-minus {
    background: url('../../../../../static/img/checkout/pepecar-baby-seat-minus.png') no-repeat center $grey-dark;
  }
  .checkout-car-extras-extra-price-description-plus {
    background: url('../../../../../static/img/checkout/pepecar-baby-seat-plus.png') no-repeat center $grey-dark;
  }

  .checkout-car-extras-extra-price-description-checkbox,
  .checkout-car-extras-extra-price-description-checkbox-included,
  .checkout-car-extras-extra-price-description-minus,
  .checkout-car-extras-extra-price-description-tick {
    float: left;
  }

  .checkout-car-extras-extra-price-description-plus {
    float: right;
  }

  .checkout-car-extras-extra-price-description-checkbox:focus,
  .checkout-car-extras-extra-price-description-minus:focus,
  .checkout-car-extras-extra-price-description-plus:focus,
  .checkout-car-extras-extra-price-description-checkbox:hover,
  .checkout-car-extras-extra-price-description-minus:hover,
  .checkout-car-extras-extra-price-description-plus:hover {
    background-color: #C00;
    cursor: pointer;
  }
}

.checkout-car-extras-box-mobile {
  .extra-checkbox-wrapper {
    .checkout-car-extras-extra {
      .checkout-car-extras-description-wrapper,
      .checkout-car-extras-price {
        display: block;
        float: left;
      }
      .checkout-car-extras-description {
        overflow: hidden;
        margin: 0;
        padding: 12px 0;
        background: none;
      }
    }
  }
}
