@import "../../../sass/pepe-theme-sass-variables";

$fixed-nav-height: 60px;

.availability-fixed-scroll-bar {
  background-color: $pepe-grey;
  height: $fixed-nav-height;
  padding: 10px;
  box-shadow: 0 1px 10px $grey-mid2;
  transform: translateY(- ($fixed-nav-height + 10));
  transition: transform .6s cubic-bezier(0.4, 0.0, 0.2, 1);

  &.visible {
    transform: translateY(0);
  }

  .navbar-fixed-btn {
    cursor: pointer;
    border: 1px solid $black;
    color: $black;
    font-size: 1.3em;
    font-family: $font;
    text-transform: uppercase;
    margin: 0 15px;
    height: $fixed-nav-height - 20px;
    line-height: $fixed-nav-height - 20px;
    text-align: center;
    letter-spacing: 1px;
    background: $white;

    &.column-change-order {
      > span {
        padding-right: 20px;
      }
      position: relative;
      select {
        opacity: 0;
        cursor: pointer;
        position: absolute;
        left: 0; top: 0;
        width: 100%;
        height: $fixed-nav-height - 20px - 2px;
      }
    }
  }

  .pepe-col-4 {
    .navbar-fixed-btn > span {
      background: transparent no-repeat left center;
      padding-left: 25px;
      background-size: 15px auto;
    }
    &:last-child .navbar-fixed-btn > span {
      padding-left: 0;
      padding-right: 25px;
      background-position: right center;
    }

    $list: 'search-edit', 'price-table', 'change-order';
    @each $key in $list {
      .navbar-fixed-btn.column-#{$key} > span {
        background-image: url('../../../static/img/icons/header/#{$key}.svg');
      }
    }
  }

  .inner {
    width: 1170px;
    margin: 0 auto;
  }

  &.new-top-filter-bar {
    background: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.30);

    .navbar-fixed-btn {
      background: #F5F5F5;
      border: 1px solid #DDDDDD;

      > span {
        font-family: $font2;
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        letter-spacing: 0;
        text-transform: none;
      }
    }

    $list: 'search-edit', 'price-table', 'change-order';
    @each $key in $list {
      .navbar-fixed-btn.column-#{$key} > span {
        background-image: url('../../../static/img/icons/header/#{$key}-new.svg');
      }
    }
  }
}
