.car-result-box .wrapper-search-includes {
  @import "car-feature-label/component";

  color: $pepe-dark-text;
  background-color: $pepe-grey;
  border-top: 1px solid $pepe-dark-grey;
  position: relative;
  padding: get-size(15px) get-size(40px);
  font-size: 0.71em;

  .inner {
    text-align: center;
    padding-top: get-size(10px);
    > * > *:last-child {
      margin-bottom: 0;
    }
  }

  .search-include-header {
    font-weight: bold;
    font-family: $font2;
    font-size: 1.2em;
  }

  .search-include-sub-header {
    font-family: $font2;
    margin-top: 5px;
  }

  .search-include-close {
    position: absolute;
    top: -1px;
    left: -1px;
    border-left: none;
    border-top: none;
  }

  .pepe-nav-button {
    width: get-size(20px);
    height: get-size(20px);
    border: 1px solid $pepe-dark-grey;
    background-color: white;
    padding: get-size(4px);

    .pepe-icon {
      width: 100%;
      height: 100%;
      vertical-align: top;
    }
  }
}
