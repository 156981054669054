.search-app-mobile {
  .ngdialog.cvc-dialog {
    padding: 30px 20px;
    .ngdialog-content {
      width: 100%;
      max-height: 100%;
      overflow-x: hidden;
      overflow-y: scroll;

      .modal-header {
        padding-top: 0;
        .modal-title {
          font-size: 24px;
        }
      }

      .text-description {
        padding-bottom: 10px;
        font-size: 13px;
      }

      .swiper-container {
        padding-bottom: 30px;
        .swiper-pagination-bullet {
          background: $pepe-dark-grey;
        }
        .swiper-pagination-bullet-active {
          background: $pepe-red;
        }
        .card-image-wrapper {
          img {
            width: 100%;
          }
        }
        .card-details-wrapper {
          margin-bottom: 10px;
          .card-name {
            font-size: 13px;
          }
        }
      }
    }
  }
}