.wrapper-touch-search-form {
  height: 100%;

  .wrapper-mode-selector {
    overflow: hidden;
    margin-bottom: 15px;

    > div {
      float: left;
      width: 50%;
      text-align: center;
      text-transform: uppercase;
      border-bottom: 1px solid transparent;
      line-height: 40px;
      &.active {
        font-weight: bold;
        border-bottom: 1px solid $black;
      }
    }
  }

  .wrapper-touch-checkbox {
    width: 100%;
  }

  .wrapper-location-input {
    .wrapper-touch-select-placeholder {
      font-size: 0.7em;
    }
  }

  .search-warning {
    font-family: $font2;
  }
}