.wrapper-cross-selling {
  font-family: $font2;
  position: absolute;
  bottom: 0;
  height: get-size(100px);
  padding: get-size(15px);
  padding-top: 0;
  width: 100%;

  .inner {
    border: 1px solid $grey;
    height: 100%;
    width: 100%;
    padding: get-size(5px);
    position: relative;
  }

  .wrapper-title {
    .title {
      z-index: 2;
      position: relative;
    }
  }
  .field-image img {
    position: absolute;
    bottom: get-size(10px);
    left: get-size(10px);
    width: get-size(60px);
    height: get-size(60px / 1.6);
  }
  .wrapper-title {
    font-size: 0.9em;
    text-align: right;
    p { margin-bottom: 0; }
    .title {
      text-transform: uppercase;
      font-family: $font;
      font-size: 1.5em;
    }
  }
  ul.characteristics {
    position: absolute;
    bottom: 0;
    right: get-size(85px);
    font-size: 0.8em;
    li {
      list-style: none;
      display: inline-block;
      span {
        margin: 0 get-size(2px);
      }
    }
  }
  .more-info {
    cursor: pointer;
    position: absolute;
    right: get-size(5px);
    bottom: get-size(5px);
    background: $black;
    color: $white;
    width: get-size(70px);
    text-align: center;
    line-height: get-size(25px);
    font-size: 0.9em;
  }
}