.wrapper-car .wrapper-layout .wrapper-tabs .wrapper-tab-active .inner-tab {
  &.info {
    > .content {

      > * {
        padding-left: get-vw-landscape(30px);
        margin-bottom: get-vw-landscape(10px);
      }

      p {
        margin-bottom: get-vw-landscape(2px);
      }

      .description  ul {
        padding: 0;
        padding-left: 20px;
        li {
          margin-bottom: 5px;
        }
      }

      .wrapper-airport {
        background: url('../../../../../../static/img/icons/main-info/airport.svg') no-repeat get-vw-landscape(5px) get-vw-landscape(2px);
        background-size: get-vw-landscape(15px) auto;
      }
      .wrapper-railway {
        background: url('../../../../../../static/img/icons/main-info/railway.svg') no-repeat get-vw-landscape(5px) get-vw-landscape(2px);
        background-size: get-vw-landscape(15px) auto;
      }
      .wrapper-city {
        background: url('../../../../../../static/img/icons/main-info/city.svg') no-repeat get-vw-landscape(5px) get-vw-landscape(2px);
        background-size: get-vw-landscape(15px) auto;
      }
      .wrapper-fuel-policy {
        background: url('../../../../../../static/img/icons/main-info/fuel-policy.svg') no-repeat get-vw-landscape(5px) get-vw-landscape(2px);
        background-size: get-vw-landscape(15px) auto;
      }
    }
    &.active {
      .toggler {
        height: get-vw-landscape(5px);
      }
    }
  }


  &.provider {
    .toggler {
      .hidden-active {
        .rating {
          width: get-vw-landscape(30px);
        }
        .info {
          width: calc(100% - #{get-vw-landscape(30px)});

          img {
            max-width: get-vw-landscape(45px);
          }
        }
      }
    }

    .content {
      .header {
        margin-bottom: get-vw-landscape(10px);

        .wrapper-image {
          flex-basis: get-vw-landscape(60px);
          img {
            max-width: get-vw-landscape(50px);
            max-height: get-vw-landscape(30px);
          }
        }

        .wrapper-rating {
          padding-left: get-vw-landscape(5px);
          flex-basis: get-vw-landscape(65px);

          .rating-text {
            margin-bottom: get-vw-landscape(5px);
          }
        }
      }
      .wrapper-table {
        line-height: get-vw-landscape(15px);

        table {
          td.rating {
            width: get-vw-landscape(34px);
          }

          td.description {
            padding-left: get-vw-landscape(12px);
          }
        }
      }
    }
  }
}
