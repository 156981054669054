$mode: 'mobile' !default;

html.root-#{$mode}.wrapper-popup-visible {
  overflow: hidden;
  body {
    overflow: hidden;
    height: auto;
  }
  #search-box .tab-content { z-index: auto; }
}

html.root-#{$mode}.is-ios {
  &, body {
    -webkit-overflow-scrolling: touch !important;
  }
}

html.root-#{$mode}.is-ios.wrapper-popup-visible {
  &, body {
    height: 100% !important;
  }
}

$header-height: 50px;
$footer-height: 50px;
$footer-height-2x: 100px;
@if $mode == 'tablet' {
  $footer-height: 65px;
  $footer-height-2x: 130px;
}

html.root-#{$mode} .wrapper-popup {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;

  &.slide-enter {
    top: 100%;
  }

  &.slide-enter-active {
    top: 0;
    transition: 500ms;
  }

  &.slide-leave {
    top: 0;
  }

  &.slide-leave-active {
    top: 100%;
    transition: 500ms;
  }

  .wrapper-popup-header {
    position: relative;

    font-size: 1.5em;
    height: $header-height;
    line-height: $header-height;
    text-transform: uppercase;
    background: $grey;
    text-align: center;
    color: $pepe-dark-text;
    border-bottom: 1px solid $pepe-dark-grey2;

    .btn-close {
      position: absolute;
      color: transparent;
      height: $header-height;
      width: $header-height;
      right: 0;
      top: 0;

      background: url('../../static/img/icons/btn-close.svg') no-repeat center center;
      background-size: 15px 15px;
    }
  }

  .wrapper-popup-inner {
    overflow-y: auto;
    height: 100%;
    padding: 15px;
    -webkit-overflow-scrolling: touch;

    background: rgba($pepe-dark-grey2, 0.95);

    &.no-transparent {
      background: $pepe-dark-grey2;
    }
  }

  &.with-header .wrapper-popup-inner {
    height: calc(100% - #{$header-height});
  }

  &.with-header.with-footer .wrapper-popup-inner {
    height: calc(100% - #{$header-height} - #{$footer-height});
  }

  .wrapper-popup-footer {
    font-size: 1.6em;
    border-top: 1px solid $pepe-dark-grey2;
    height: $footer-height;
    line-height: $footer-height;
    text-align: center;
    text-transform: uppercase;
    background: $grey;
    &, a { color: $pepe-red; }

    .btn-footer {
      height: $footer-height;
      line-height: $footer-height;
    }

    .phone-number {
      display: inline-block;
      line-height: $footer-height;
      padding-left: 25px;
      background: url('../../static/img/icons/icon-phone.svg') no-repeat left center;
      background-size: 20px 20px;
    }
  }
}

.ngdialog.ngdialog-theme-default.fuel-policy .ngdialog-content .modal-body {
  font-size: 14px;
}
