.car-result-box .wrapper-image {
  position: relative;
  .wrapper-image-img {
    padding: get-size(20px);
    .image-main {
      margin: 0 auto;
      width: 100%;
      height: 100%;
    }
  }
}
