@import "../../checkout-sass-variables";
@import "../../../../../sass/pepe-theme-sass-variables";

.insurance-wrapper {
  border: 1px solid $pepe-black-text;
  .insurance-body-wrapper {
    font-family: $font2;
    padding: 50px !important;
    .insurance-logo {
      text-align: right;
    }
    .insurance-title {
      font-size: 1.714em;
      margin-top: 20px;
      margin-bottom: 10px;
      line-height: 1em;
    }
    .benefit-item {
      &:before {
        position: absolute;
      }
      .benefit-wrapper {
        padding: 0 1.5em 0 0.85em;
        .benefit-title {
          margin-bottom: 5px;
          display: inline-block;
        }
      }
    }

  }
  .insurance-title-wrapper {
    height: 55px;
    .new-checkbox {
      .glyphicon {
        font-size: 1.5em;
        color: white;
        padding: 13px 16px;
        display: inline-block;
      }
    }
    .insurance-price-box {
      float: left;
      text-align: center;
      font-size: 1em;
      padding: 0 20px;
      line-height: 55px;
      border-right: 1px solid $pepe-black-text;
      background: $pepe-dark-grey;
      font-family: $font4;
    }
    .insurance-header-box {
      float: left;
      width: calc(100% - 90px);
      line-height: 55px;
      height: 100%;
      letter-spacing: 1px;
      background-color: white;
      .insurance-focus-text {
        padding: 0 20px;
        font-family: $font4;
        text-transform: uppercase;
        display: inline-block;
      }
      .help-title-box {
        font-size: 0.85em;
        font-family: $font5;
        max-width: calc(100% - 401px);
        display: inline-block;
        line-height: 1em;
        vertical-align: middle;
      }
      .insurance-logo {
        float: right;
        padding: 10px 20px;
        height: 100%;
        .insurance-logo-img {
          height: 100%;
          vertical-align: top;
        }
      }
    }
  }
  .insurance-accept {
    border-radius: 10px;
    .input-label {
      color: $pepe-light-blue;
      width: calc(100% - 20px);
      font-weight: 400;
    }
    .input-wrap {
      position: relative;
      margin: 0 auto 1em;
      display: inline-block;
      vertical-align: top;
      width: 370px;
      height: 80px;
    }
    .input-wrap--small {
      width: 20px;
      height: 20px;
      float: left;
      margin: 23px 0;
    }
    .input-wrap input[type="text"] {
      display: block;
      width: 94.6%;
      height: 75%;
      border: none;
      position: relative;
      background: none;
      z-index: 100;
      outline: none;
      font-size: 1.5em;
      font-weight: bold;
      color: #aaa;
      left: 2.7%;
      top: 12.5%;
      padding: 0 0.75em;
      -webkit-tap-highlight-color: transparent;
      -webkit-tap-highlight-color: transparent;
      /* For some Androids */
    }
    .input-wrap input[type="checkbox"],
    .input-wrap input[type="radio"],
    .input-wrap .fa {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 100;
      opacity: 0;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
      -webkit-tap-highlight-color: transparent;
      /* For some Androids */
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    .input-wrap .fa {
      z-index: 99;
      line-height: 20px;
      color: #CC0000;
      left: 1px;
    }
    .input-wrap input[type="checkbox"]:checked + .fa,
    .input-wrap input[type="radio"]:checked + .fa {
      opacity: 1;
    }
    .morph-shape {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      pointer-events: none;
    }
    .morph-shape svg {
      border: 2px solid $pepe-light-blue;
    }
    .morph-shape svg path {
      fill: transparent;
    }
  }
  .insurance-logo-img {
    width: 200px;
  }
}

.insurance-info {
  .ngdialog-content {
    width: 70% !important;
  }

  .insurance-info-card {
    margin-top: 35px;
    border-radius: 2px;
    padding: 10px 10px 10px 100px;
    background: url("../../../../../static/img/icons/icon-insurance-card.png") center left 10px no-repeat #F5F5F5;
  }
}
