@import "./common/layout";

@media (orientation: landscape) {
  @import "./landscape/variables";
  @import "./landscape/layout";
}

@media (orientation: portrait) {
  @import "./portrait/variables";
  @import "./portrait/layout";
}
