.wrapper-result-box {
  width: $width;
  float: right;
  min-height: 1000px;
}

.car-result-box {
  width: $width;
  margin: 0 auto;
  box-sizing: border-box;
  border: 1px solid $pepe-dark-grey;
  margin-bottom: get-size(10px);
  font-size: get-size(14px);
  position: relative;

  .car-header {
    height: $header-height;
  }

  .search-result-columns {
    overflow: hidden;
    width: 100%;
    > .wrapper-image { width: $col-image-width; }
    > .wrapper-info { width: $col-info-width; }
    > .wrapper-price { width: $col-price-width; }
    > * { height: $inner-height; float: left; }
  }
}
