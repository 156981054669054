.wrapper-popup.wrapper-time-picker-modal {
  background: rgba(0,0,0,0.4);
}

.wrapper-time-picker-modal {
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 1.5428em;
  .time-picker-content {
    background: white;
    width: 80%;
    padding: 40px 0 70px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    position: relative;
  }
}

.wrapper-submode-search {

  .wrapper-touch-select-placeholder {
    .inner {
      display: flex;
      align-items: center;
      font-size: 14px;
      gap: 10px;
    }
  }
  
  .DateInput {
    width: 100%;
    font-weight: 200;
    vertical-align: middle;
    border-radius: 10px !important;
    line-height: 40px !important;
    height: 40px !important;
    padding: 0 12px !important;
    font-family: "Montserrat",sans-serif;
    background: #ececec !important;
    margin-bottom: 10px !important;
    font-size: inherit !important;
    color: #191918 !important;
    .DateInput_input {
      display: block;
      width: 100%;
      height: 100%;
      background: url('../../../static/img/icons/wrapper-touch-select-placeholder.svg') no-repeat right center;
      line-height: inherit;
      font-size: inherit;
    }
  }

  .touch-time-picker {
    &__container {
      border-radius: 10px;
      line-height: 40px;
      height: 40px;
      padding: unset;
      font-family: "Montserrat", sans-serif;
      background: #ececec;
      margin-bottom: 10px;
    }
    &__content {
      background: url('../../../static/img/icons/wrapper-touch-select-placeholder.svg') no-repeat right center;
      background-size: 12px auto;
      color: #333;
      font-size: 14px;
      width: 100%;
    }
  }
}

.touch-time-picker {
  &__container {
    width: 100%;
    margin-bottom: 10px;
    border-radius: 0;
    background: $pepe-grey;
    display: flex;
    align-items: center;
    gap: 19px;
    // padding: 19px;
    height: 34px;
    // keeping old styles for the moment
    padding: 0 12px !important;
  }
  &__content {
    font-weight: normal;
    // font-size: 16px;
    // line-height: 150%;
    // letter-spacing: 0.15px;
    // keeping old styles for the moment
    font-size: 13px;
    line-height: 34px;
    color: rgb(156, 156, 156);
    // width: 100%;
  }
  &__options {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
    color: #FFFFFF;
  }
  &__option {
    padding: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    position: relative;
    &.selected {
      background-color: #4B4B4B;
    }
    svg {
      position: absolute;
      height: 100%;
      top: 0;
    }
  }
}