@import "../../../../../sass/pepe-theme-sass-variables.scss";

.checkout-car-extras-box {
  background-color: $pepe-grey;
  .panel-body {
    padding: 25px 0;
  }
  .checkout-car-extras-heading {
    background-color: $pepe-mid-grey;
    padding: 20px 25px;
    position: relative;
    cursor: pointer;

    .checkout-car-extras-panel-display {
      display: block;
      &:after {
        content: "";
        position: absolute;
        width: 15px;
        height: 8px;
        top: 50%;
        right: 20px;
        background: url('../../../../../static/img/icons/wrapper-touch-select-placeholder.svg') no-repeat right center;
        background-size: 15px auto;
        transform: rotate(180deg);
      }
      &.collapsed:after {
        /* rotate "arrow-down" */
        transform: rotate(0deg);
      }
    }
    .checkout-car-extras-description {
      .car-info-bubble {
        padding-right: 30px !important;
        &:last-child {
          padding-right: 0 !important;
        }
      }
      .checkout-car-extras-miniatures {
        display: inline-block;
        vertical-align: middle;
      }
      .checkout-car-extras-title {
        text-transform: uppercase;
        width: 250px;
        display: inline-block;
        line-height: 55px;
      }
    }
    .checkout-car-extras-price-box {
      padding: 0 65px;
      text-align: center;
      .checkout-car-extras-price-description,
      .checkout-car-extras-price {
        display: inline-block;
        height: 55px;
        line-height: 55px;
        float: left;
      }
      .checkout-car-extras-price-description {
        width: 60%;
        margin-right: 20px;
        display: inline-block;
        background-color: $pepe-dark-grey;
      }
      .checkout-car-extras-price {
        width: calc(40% - 20px);
        background-color: white;
      }
    }
  }
  .checkout-car-extras-body {
    overflow: hidden;
    transition: height ease-in-out .15s;
  }
  .checkout-car-extras-extra {
    padding: 20px 25px;
    position: relative;
    .checkout-car-extras-extra-description {
      display: inline-block;
      width: calc(100% - 55px);
    }
    .checkout-car-extras-extra-price-description,
    .checkout-car-extras-extra-price {
      display: inline-block;
      height: 55px;
      line-height: 55px;
      float: left;
    }
    .checkout-car-extras-extra-price-description {
      width: 60%;
      margin-right: 20px;
      display: inline-block;
      padding: 12px 0;
    }

    .baby-seat-selector-wrapper {
      width: 35%;
      margin-left: 25%;
    }

    .checkout-car-extras-extra-price-included {
      width: 60%;
      margin-right: 20px;
      display: inline-block;
      height: 55px;
      line-height: 55px;
      float: left;
    }
    .checkout-car-extras-extra-price-label {
      display: inline-block;
      float: right;
      line-height: 30px;
      padding-right: 20px;
      cursor: pointer;
    }
    .checkout-car-extras-extra-price-checkbox {
      display: inline-block;
      border: 1px solid $pepe-dark-grey-3;
      width: 30px;
      height: 30px;
      background: $white;
      position: relative;
      float: right;
      cursor: pointer;
      &.selected:after {
        content: '';
        background: url('../../../../../static/img/checkout/pepecar-extra-selected.png') no-repeat center;
        width: 29px;
        height: 29px;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .checkout-car-extras-extra-price {
      width: calc(40% - 20px);
      background-color: white;
    }
    .checkout-car-extras-extra-price-box {
      padding: 0 65px;
      text-align: center;
    }
  }

}

.checkout-car-extras-extra{
  .checkout-car-extras-extra-price-description-checkbox,
  .checkout-car-extras-extra-price-description-checkbox-included,
  .checkout-car-extras-extra-price-description-minus,
  .checkout-car-extras-extra-price-description-plus,
  .checkout-car-extras-extra-price-description-tick{
    background-color: $pepe-black-text;
    color: #fff;
  }

  .baby-seat-number {
    width: calc(100% - 60px);
    float: left;
    line-height: 30px;
  }

  .baby-seat-button {
    width: 30px;
    height: 30px;
    display: block;
    top: 0;
    line-height: 29px;
  }

  .checkout-car-extras-extra-price-description-minus {
    background: url('../../../../../static/img/checkout/pepecar-baby-seat-minus.png') no-repeat center $grey-dark;
  }
  .checkout-car-extras-extra-price-description-plus {
    background: url('../../../../../static/img/checkout/pepecar-baby-seat-plus.png') no-repeat center $grey-dark;
  }

  .checkout-car-extras-extra-price-description-checkbox,
  .checkout-car-extras-extra-price-description-checkbox-included,
  .checkout-car-extras-extra-price-description-minus,
  .checkout-car-extras-extra-price-description-tick{
    float: left;
  }

  .checkout-car-extras-extra-price-description-plus{
    float: right;
  }

  .checkout-car-extras-extra-price-description-checkbox:focus,
  .checkout-car-extras-extra-price-description-minus:focus,
  .checkout-car-extras-extra-price-description-plus:focus,
  .checkout-car-extras-extra-price-description-checkbox:hover,
  .checkout-car-extras-extra-price-description-minus:hover,
  .checkout-car-extras-extra-price-description-plus:hover{
    background-color: #C00;
    cursor: pointer;
  }
}
