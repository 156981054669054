.car-result-box .car-header {
  background-color: $pepe-grey;
  padding-left: get-size(50px);

  &:after {
    content: "\0020";
    display: block;
    height: 0;
    clear: both;
    overflow: hidden;
    visibility: hidden;
  }

  .pepe-title-text {
    font-family: $font4;
    line-height: $header-height;
    float: left;
  }

  .wrapper-guaranteed-mam {
    float: left;
    padding-top: get-size(5px);

    .guaranteed-mam {
      border-left: 1px solid $black;
      letter-spacing: initial;
      text-transform: initial;
      font-family: $font2;
      font-size: 0.857em;
      font-weight: bold;

      width: get-size(120px);
      line-height: 1.1em;

      margin-left: get-size(10px);
      padding-left: get-size(10px);
    }
  }

  .wrapper-car-special-label {
    float: left;
    height: 100%;
    margin-left: get-size(25px);

    display: flex;
    align-items: center;

    .car-special-label {
      color: $white;
      background: #439c49;
      padding: 3px 5px;
      border-radius: 2px;
      font-family: $font2;
      font-size: 0.857em;
      font-weight: bold;
    }
  }
}
