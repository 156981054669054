.wrapper-car .wrapper-header {
  height: $header-height;
  line-height: $header-height;
  padding-left: get-vw(10px);
  text-transform: uppercase;
}

.wrapper-car.recommended .wrapper-header {
  padding-left: get-vw(40px);
  background: url('../../../../../static/img/icons/recommended.svg') no-repeat get-vw(10px) center;
  background-size: get-vw(20px) get-vw(20px);
}
