.wrapper-booking-info {
  height: $row-info-height;

  .fa {
    color: $pepe-red;
    line-height: get-size(28px);
  }

  .booking-info-icon-wrapper {
    width: get-size(28px);
    height: get-size(28px);
    padding: get-size(4px);
    display: inline-block;

    .pepe-icon {
      width: 100%;
      height: 100%;
    }
  }

  .wrapper-place,
  .wrapper-fuel-policy {
    padding: get-size(10px) 0;
    padding-left: get-size(20px);
    cursor: help;
  }

  .pickup-place-detail-box,
  .fuel-policy-detail-box {
    font-size: 0.85em;
    display: inline-block;
    margin-left: get-size(5px);
    margin-right: get-size(10px);
    vertical-align: top;
    max-width: 80%;

    .detail-header {
      font-family: $font2;
    }
    .detail-text {
      line-height: 1em;
      font-family: $font2;
      font-weight: bold;
    }
  }
}