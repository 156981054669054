.car-resume {
  background-color: white;
  border: 1px solid $pepe-dark-text;
  height: $car-resume-height;
  position: relative;
  font-family: $font2;

  .box-features {
    cursor: auto;
  }

  .car-resume-img-wrapper {
    padding: 35px 30px;
    > img {
      width: 100%;
      height: 100%;
    }
  }
  .car-resume-info-wrapper {
    padding-right: 30px;
    .car-resume-info {
      height: 55%;
      padding-top: 20px;
      .car-resume-name {
        font-family: $font4;
        font-size: 1.5em;
        height: 20%;
        line-height: 1em;
        text-transform: uppercase;
        color: black;
      }
      .details {
        height: 80%;
      }
      .provider {
        bottom: 20px;
        left: 0px;
        position: absolute;
        img {
          max-width: 90px;
          height: auto;
        }
      }
      .car-price-wrapper {
        text-align: right;
        font-family: $font4;
        .car-total-price {
          font-size: 1em;
          text-transform: uppercase;
          line-height: 1.64em;
          .price {
            font-size: 1.64em;
          }
        }
        .tax-included {
          font-size: 0.85em;
          font-family: $font2;
        }
        .price-per-day {
          font-size: 1.64em;
          line-height: 1.64em;
          color: $pepe-red;
        }
      }
    }
    .car-resume-booking-info-wrapper {
      height: 45%;
      padding-bottom: 20px;
      font-size: 0.85em;
      font-family: $font2;
      line-height: 1.2em;
      .wrapper-fuel-policy, .pickup-place-wrapper {
        margin-bottom: 15px;
      }
      .bold {
        font-family: $font2;
      }
      .uppercase {
        text-transform: uppercase;
      }
      .wrapper-text {
        display: inline-block;
        vertical-align: top;
        max-width: 85%;
      }
      .car-resume-booking-info-icon {
        width: 28px;
        height: 28px;
        margin-right: 10px;
        padding: 3px;
        vertical-align: top;
        display: inline-block;
        .pepe-icon {
          width: 100%;
          height: 100%;
        }
      }
      .details {
        height: 80px;
        position: relative;
      }
      .car-resume-booking-day, .car-resume-booking-time {
        text-align: center;
        display: inline-block;
        height: 28px;
        line-height: 28px;
        font: $font2;
        margin-bottom: 15px;
      }
      .car-resume-booking-day {
        width: 60%;
        background-color: $pepe-black-text;
        color: white;
      }
      .car-resume-booking-time {
        width: 40%;
        background-color: $pepe-grey;
        color: $pepe-black-text;
      }
    }
  }

  .car-resume-terms {
    margin-top: -8px;
    text-align: center;
  }
}