.popup-car-provider-review {
  padding-bottom: 160px;
  padding-top: 160px;
  font-family: $font2; 

  .review-close {
    cursor: pointer;
    background-color: $pepe-mid-grey;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
    position: absolute;
    width: 40px;
    height: 40px;
    right: -20px;
    top: -20px;
    border-radius: 20px;
    z-index: 5;
  }

  .ngdialog-content {
    position: relative;
    margin: 0 auto;
  }
  .ngdialog-content,
  .modal-dialog {
    width: 750px;
  }

  .modal-content {
    border-radius: 0;
  }

  .review-points {
    width: 100%;
    font-size: 1.1em;

    .points {
      border-right: 2px solid $pepe-red;
      color: $pepe-red;
      line-height: 3em;
      text-align: center;
      width: 16%;
      
    }
    .review-points-text {
      padding-left: 1.6em;
    }

    tr.marked {
      .points {
        border-right: 4px solid $pepe-red;
        font-weight: bold;
      }
    }
  }

  .review-points tr:nth-child(even) {
    background-color: $pepe-mid-grey;
  }

  .review-general-data {
    position: absolute;
    height: 100%;
    bottom: 0;
    right: 0;

    .review-logo {
      margin-top: 30px;
      text-align: center;
      padding: 0 2em;
      width: 100%;
      img {
        width: 100%;
      }
    }

    .review-total {
      color: $pepe-red;
      padding: 0 2em;
      margin-top: 2em;
      text-align: center;

      .review-total-wrapper {
        font-size: 1.71em;

        .adjective {
          font-size: 0.9em;
        }
      }

      .score {
        font-family: $font4;
        font-size: 2.3em;
        line-height: 1em;
      }

      .max {
        color: $pepe-dark-text;
      }
    }

    .reviews-number {
      background-color: $pepe-dark-grey2;
      border-top: 5px solid $pepe-red;
      height: 46px;
      bottom: 0;
      color: $pepe-red;
      position: absolute;
      text-align: center;
      padding: 0.7em 0;
      width: 100%;
    }
  }
}
