.wrapper-car .wrapper-layout .wrapper-tabs .wrapper-tab-active .wrapper-tab-taxes {
  padding: get-vw-landscape(5px);
  .group {
    padding-left: get-vw-landscape(30px);
    margin-bottom: get-vw-landscape(10px);

    .title {
      margin-bottom: get-vw-landscape(2px);
    }

    $list: 'airport-tax', 'young-driver', 'nightly', 'one-way', 'full-empty-refund-tax', 'others';
    @each $key in $list {
      &.#{$key} {
        background: url('../../../../../../static/img/icons/taxes/#{$key}.svg') no-repeat get-vw-landscape(2px) top;
        background-size: get-vw-landscape(20px) get-vw-landscape(20px);
        min-height: get-vw-landscape(20px);
      }
    }
  }
}
