@import "../../../../../sass/pepe-theme-sass-variables";

$submit-wrapper-hover-icon-size: 22px;

.checkout-submit-fixed {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  height: 105px;
  padding: $desktop-padding 0;
  background-color: white;
  z-index: 99999;
  transform: translateY(105px);
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);

  &.visible {
    transform: translateY(0);
  }

  .checkout-submit-wrapper {
    width: $desktop-size;
    position: relative;
    margin: 0 auto;
    padding: 0 $desktop-padding;
    height: 100%;

    .checkout-submit-hover-button {
      cursor: pointer;
      width: $submit-wrapper-hover-icon-size;
      height: $submit-wrapper-hover-icon-size;
      background-color: $pepe-red;
      color: white;
      position: absolute;
      top: -10px;
      right: -15px;
      text-align: center;
      font-family: $font4;
      line-height: $submit-wrapper-hover-icon-size;
      border-radius: round($submit-wrapper-hover-icon-size);
      z-index: 5;
    }

    .checkout-pay-wrapper {
      padding-left: 30px;
      line-height: 55px;
    }

    .checkout-disclaimer {
      line-height: 1.3em;
      width: 150px;
      font-size: 0.85em;

      .red {
        color: $pepe-red;
      }

      .strong {
        font-family: $font4;
      }
    }

    .checkout-submit-button {
      float: right;
    }

    .car-image {
      height: 100%;
      width: 100%;
    }
  }
}

.checkout-submit-car-resume-wrapper {
  position: fixed;
  bottom: -220px;
  right: 0;
  left: 0;
  width: 1170px;
  z-index: 5;
  margin: 0 auto;

  transition: bottom 1s;

  &.visible { bottom: 105px; }

  .car-resume { border: 0; }
}
