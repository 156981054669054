@import "react-datepicker/dist/react-datepicker.css";

@import "pepe-theme";
@import 'autosuggest';
@import "fonts";
@import './common/calendar';

// Desktop styles
@import 'desktop/popup';
@import "../components/common/tabs/style";
@import "../components/common/datetime-range-input/component";

@import "../components/common/price-booking-resume/price-booking-resume";

@import "../components/desktop/loader/loader";
@import "../components/desktop/search-results/style";
@import "../components/desktop/availability-fixed-scroll-bar/component";
@import "../components/desktop/checkout/checkout";
@import "../components/desktop/cvc-info/component";

@import "../components/desktop/price-table/price-table-correction";
@import "../components/desktop/search-box/search-box";
@import "../components/desktop/contact-banner/contact-banner";
@import "../components/desktop/filters-form/filters-form";
@import "../components/desktop/car/car-info-bubble/car-info-bubble";
@import "../components/desktop/insurance-form-v2/component";

@import "../components/touch/touch-checkbox/component";
@import "../components/touch/touch-select-placeholder/component";
@import "../components/touch/touch-time-picker/component";

@import "../components/common/login-form-block/login-form/component";

html.root-desktop {
  $width: 845px;
  @import "../components/desktop/car/component";
}

// Mobile styles
@import 'touch/popup';

html.root-mobile {
  height: auto;

  @import "touch/icons";
  @import '../components/mobile/root/component';
  @import '../components/mobile/fixed-header/component';
  @import '../components/mobile/fixed-footer/component';
  @import '../components/mobile/search-summary/component';
  @import '../components/mobile/submode-price-table/component';
  @import '../components/mobile/submode-filter/component';
  @import '../components/mobile/search-form/component';
  @import '../components/mobile/car/component';
  @import '../components/mobile/checkout/component';
  @import '../components/mobile/mode-list/component';
  @import '../components/mobile/search-box-landing/component';
  @import "../components/mobile/cvc-info/component";

  @import "mobile/chat";
}

$mode: 'tablet';
@import 'touch/popup';

// Tablet styles
html.root-tablet {
  height: auto;
  @import "touch/icons";
  @import '../components/mobile/root/component';
  @import 'tablet/fixed-header';
  @import '../components/mobile/fixed-footer/component';
  @import 'tablet/search-summary';
  @import 'tablet/filter-form';
  @import '../components/tablet/submode-price-table/component';
  @import '../components/tablet/filter-form/component';
  @import '../components/tablet/submode-filter/component';
  @import '../components/tablet/search-box-landing/component';
  @import 'tablet/search-form';
  @import 'tablet/contact-banner';
  @import "../components/tablet/price-booking-resume/component";

  @media (min-width: 700px) {
    $width: 650px;
    @import "../components/desktop/car/component";
    @import '../components/tablet/mode-list/component';
    @import "tablet/car";

    @import 'tablet/checkout';
  }

  @media (min-width: 800px) {
    $width: 750px;
    @import "../components/desktop/car/component";
    @import '../components/tablet/mode-list/component';
    @import "tablet/car";

    @import 'tablet/checkout';
  }
}

// @TO REVERT - PEPE-831
html.root-desktop {
  .checkout-step .checkout-submit-fixed {
    width: 100vw;
    position: relative;
    margin-left: calc(((100vw - 100%) + 25px) / 2 * -1);
    transform: translateY(0);
  }
  .checkout-step-1 .checkout-form-wrapper {
    padding-bottom: 30px;
  }
  .checkout-step .checkout-submit-car-resume-wrapper { display: none; }
}

iframe#edge-tier-chat:not(.edge-tier-chat-open) {
  z-index: 1041;
}
