.wrapper-car .wrapper-layout .wrapper-tabs .wrapper-tab-active .wrapper-tab-extras {
  .group {
    padding: get-vw-landscape(10px);
    padding-bottom: 0;
    .content {
      > * {
        margin-bottom: get-vw-landscape(5px);
      }
    }
  }
}
