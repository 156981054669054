@import "../../../sass/pepe-theme-sass-variables";

.contact-banner {
  font-family: $font3;
  border: 1px solid $pepe-red;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 10px;
  text-align: center;
  font-weight: 700;

  .contact-title {
    font-size: 1.8em;
    padding-left: 20px;
    margin-bottom: 5px;

    background: url('../../../static/img/icons/icon-phone.svg') no-repeat left center;
    background-size: 20px auto;
  }

  .contact-phone-main {
    font-size: 4em;
    line-height: 1em;
    margin-bottom: 5px;
  }
}
