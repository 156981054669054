@function get-vw-landscape($target) {
  $design-width: 320;
  $vw-context: ($design-width * .01) * 1px;
  @return ($target / $vw-context) * 1vw * 0.6;
}

$width: get-vw-landscape(330px);
$height: get-vw-landscape(218px);
$tabs-selector-width: get-vw-landscape(49px);
$header-height: get-vw-landscape(32px);
$footer-height: get-vw-landscape(35px);
$tabs-height: $height - $footer-height;

$main-info-width: get-vw-landscape(94px);
$main-info-height: get-vw-landscape(100px);
$cancellation-width: get-vw-landscape(65px);
$total-price-width: get-vw-landscape(100px);

$tab-summary-height: $header-height;
$tab-info-height: get-vw-landscape(60px);
$tab-terms-height: get-vw-landscape(40px);
$tab-extras-height: get-vw-landscape(46px);
$tab-taxes-height: get-vw-landscape(40px);