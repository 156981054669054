@import "../../../sass/pepe-theme-sass-variables";

.wrapper-car {
  .wrapper-row {
    border-bottom: 1px solid $pepe-dark-grey;
  }
}

@import "sass/layout";
@import "sass/header";
@import "sass/actions";
@import "sass/tabs";
