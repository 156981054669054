@import "../../../sass/pepe-theme-sass-variables";

.wrapper-submode-filter.wrapper-popup.with-header.with-footer.wrapper-submode-filter {
  .wrapper-popup-inner {
    height: calc(100% - 100px);
  }
  .wrapper-popup-footer {
    height: 50px;
    background: rgba($pepe-dark-grey2, 0.95);

    .wrapper-phone-number {
      border-top: 2px solid $pepe-dark-grey2;
      background: $pepe-dark-grey;
    }

    .wrapper-actions {
      .btn-footer {
        color: $red;
        background: $pepe-mid-grey;
        text-transform: uppercase;
        display: block;
        line-height: 50px;
        height: 50px;
        &.inactive {
          color: $grey-mid;
        }

        &.btn-reset-filters {
          background: $grey;
        }
      }
    }
  }
}
