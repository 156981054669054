@import "../../../../sass/pepe-theme-sass-variables";

@import "./common/actions";

@media (orientation: landscape) {
  @import "./landscape/variables";
  @import "./landscape/actions";
}

@media (orientation: portrait) {
  @import "./portrait/variables";
  @import "./portrait/actions";
}
