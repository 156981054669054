.car-result-box .wrapper-price {
  position: relative;
  padding: get-size(10px);
  .pepe-icon-reversed {
    transform: rotate(180deg);
  }
  .results-prices {
    text-align: right;

    .previous-price {
      font-size: 0.85em;
      .price {
        font-size: 1.4em;
        text-decoration: line-through;
      }
    }
    .total-price {
      font-size: 1.6em;
      line-height: 0.9em;
      .title {
        font-size: 0.8em;
      }
    }
    .previous-price, .total-price, .price-per-day {
      font-family: $font4;
    }
    .tax-included {
      font-family: $font2;
      font-weight: bold;
      margin-top: get-size(4px);
    }
    .price-per-day {
      text-transform: inherit;
      color: $pepe-red;
      margin-bottom: 0;
    }
  }

  .rent-button {
    width: 100%;
    position: relative;
    display: inline-block;
    overflow: hidden;
    height: get-size(50px);
    line-height: get-size(50px);
    font-size: 1.71em;
    font-family: $font;
    color: white !important;
    background-color: $pepe-black-text;
    padding: get-size(10px);
    margin: 0;
    cursor: pointer;

    .rent-button__background-text,
    .rent-button__top {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      right: 0;
      transition: transform .3s cubic-bezier(0.4, 0.0, 0.2, 1);
    }
    .rent-button__top {
      background-color: $pepe-red;
      text-align: center;
    }
    &:hover {
      .rent-button__background-text {
        transform: translateX(28%);
      }
      .rent-button__top {
        transform: translateX(100%);
      }
    }
  }

  .rent-button-new {
    width: 100%;
    position: relative;
    display: inline-block;
    overflow: hidden;
    height: get-size(50px);
    line-height: get-size(50px);
    font-size: 1.71em;
    font-family: $font;
    color: white !important;
    background-color: $pepe-red;
    padding: get-size(10px);
    margin: 0;
    cursor: pointer;
    border: none;

    .rent-button-new__background-text {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      right: 0;
    }

    &:hover {
      background-color: $pepe-black-text;
    }
  }

  .booking-nav-options {
    position: absolute;
    width: 100%;
    padding: get-size(10px);
    right: 0;
    bottom: 0;

    .box-terms {
      height: get-size(20px);
      margin-bottom: get-size(8px);
      cursor: pointer;

      .terms-button {
        line-height: get-size(20px);
        vertical-align: top;
        color: $pepe-dark-text !important;
      }
      .terms-popup {
        font-family: 'Montserrat';
        text-align: left;
        .modal-checkout {
          position: fixed;
          background: #4B4B4B7F;
          backdrop-filter: blur(4px);

          width: 100%;
          height: 100%;
          top: 0;
          left: 0;

          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 99999;
          &-container {
              border-radius: 16px;
              background-color: #FFF;
          }
          &-header {
              display: flex;
              justify-content: space-between;
              padding: 14px 6px 14px 16px;
              h6 {
                font-weight: 700;
                font-size: 20px;
                line-height: 160%;
                letter-spacing: 0.15px;
                color: #3B3B3B;
              }
              svg {
                  cursor: pointer;
              }
          }
          &-footer {
              padding: 16px;
              display: flex;
              justify-content: space-between;
              align-items: center;

              button.checkout-submit {
                  min-width: 124px;
                  margin: 0;
                  margin-top: 40px;
                  padding: 15px 28px;
                  height: 56px;
                  background: #D6322C;
                  border-radius: 3px;
                  border: 0;
                  font-style: normal;
                  font-weight: 700;
                  font-size: 15px;
                  line-height: 26px;
                  text-align: center;
                  letter-spacing: 0.46px;
                  text-transform: uppercase;
                  color: #FFFFFF;
                  cursor: pointer !important;
              }
          }
        }
        .termsNconditions-popup {
          &-content {
              max-width: 1153px;
              max-height: 544px;
              display: flex;
          }
          &-index {
              padding: 16px;
              display: flex;
              flex-direction: column;
              gap: 16px;
              &-item {
                  display: flex;
                  flex-direction: row;
                  gap: 8px;
                  align-items: center;
                  span {
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 24px;
                      letter-spacing: 0.15px;
                      text-decoration-line: underline;
                      color: #3B3B3B;
                      cursor: pointer;
                      &.selected {
                          font-weight: 700;
                      }
                  }
              }
          }
          &-body {
              overflow-y: auto;
              height: 544px;
              width: calc(100% - 383px); //orignal size is 371, but giving margin cause when is bold, the longest copy jumps to new line
              padding: 16px;
              &-header {
                  display: flex;
                  flex-direction: column;
                  gap: 16px;
                  a {
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 150%;
                      letter-spacing: 0.15px;
                      text-decoration-line: underline;
                      color: #3B3B3B;
                      &:hover {
                          color: #D6322C;
                      }
                  }
              }
              &-content {
                  margin-top: 64px;
                  &-section {
                      display: flex;
                      flex-direction: column;
                      &:not(:first-of-type):before {
                          content: '';
                          height: 1px;
                          background-color: #3B3B3B;
                          margin-bottom: 32px;
                      }
                      h4 {
                          font-weight: 700;
                          font-size: 24px;
                          line-height: 133.4%;
                          color: #3B3B3B;
                          margin-bottom: 32px;
                      }
                      p {
                          h5 {
                              letter-spacing: 0.15px;
                              color: #3B3B3B;
                          }
                          h5 > b > i > u {
                              font-style: normal;
                              font-weight: 700;
                              font-size: 20px;
                              line-height: 160%;
                              text-decoration: none;
                          }
                          h5 > i {
                              font-style: normal;
                              font-weight: 700;
                              font-size: 16px;
                              line-height: 150%;
                          }
                          div {
                              font-style: normal;
                              font-weight: 400;
                              font-size: 14px;
                              line-height: 24px;
                              &:last-of-type {
                                  margin-bottom: 32px;
                              }
                          }
                      }
                  }
              }
          }
        }
      }
    }
    .wrapper-toggler-includes {
      height: get-size(20px);
      position: relative;
      cursor: pointer;

      .pepe-detail-text {
        line-height: get-size(20px);
        vertical-align: top;
      }

      .pepe-nav-button {
        width: get-size(20px);
        height: get-size(20px);
        padding: get-size(4px);
        border: 1px solid $pepe-dark-grey;
        background-color: white;

        .pepe-icon {
          vertical-align: top;
          width: 100%;
          height: 100%;
          background-size: 100% auto;
        }
      }
      .toggler-includes-open {
        position: absolute;
        right: -1px;
        top: -1px;
      }
    }

    .box-extra-charges {
      width: 100%;
      position: relative;
      padding-bottom: get-size(8px);

      .inner {
        &:after {
          content: "\0020";
          display: block;
          height: 0;
          clear: both;
          overflow: hidden;
          visibility: hidden;
        }
      }

      .box-extra-charges-icons,
      .box-extra-charges-text {
        float: left;
      }
      .box-extra-charges-icons {
        width: 30%;
        display: flex;
        justify-content: space-around;
        padding-right: get-size(7px);
        > * {
          padding-right: 0 !important;

          .car-info-bubble-img.tax-icon {
            display: block;
            width: get-size(22px);
            height: get-size(22px);
            background: $pepe-dark-grey no-repeat center center;
            background-size: get-size(22px) auto;

            &.tax-icon-late {
              background-image: url('../../../../static/img/icons/taxes/nightly.svg');
            }

            $list: 'airport-tax', 'young-driver', 'one-way', 'full-empty-refund-tax', 'others';
            @each $key in $list {
              &.tax-icon-#{$key} {
                background-image: url('../../../../static/img/icons/taxes/#{$key}.svg');
              }
            }
          }
        }
      }
      .box-extra-charges-text {
        width: 70%;
      }
      .box-extra-charges-text {
        font-size: 0.6em;
        font-family: $font2;
        font-weight: normal;

        .title {
          font-weight: bold;
          text-transform: uppercase;
        }
      }
    }
  }
}
