@import "../../../sass/pepe-theme-sass-variables";

.wrapper-search-summary {
  position: relative;
  background: $pepe-black-text;
  padding: 25px 20px 10px;
  font-size: 13px;
  text-align: left;
  color: $white;
  font-family: $font2;
  margin-bottom: 20px;

  .wrapper-location {
    width: 280px;
    overflow: hidden;
    .column {
      float: left;
      &:first-child { width: 80px; }
      &:last-child { width: 200px; }
    }

    .details {
      color: $grey-mid2;
      overflow: hidden;
      span {
        display: block;
        float: left;
        margin-right: 10px;
        &:last-child { margin-right: 0; }

        &.weekday {
          width: 70px;
          text-transform: capitalize;
        }
      }
    }
  }

  .btn-edit-search {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    color: transparent;
    background: url('../../../static/img/icons/summary/search-edit.svg') no-repeat center center;
    background-size: 15px 15px;
    border: 1px solid $grey-mid2;
    border-radius: 5px;
  }
}
