.wrapper-touch-checkbox {
  position: relative;

  width: 180px;
  height: 36px;

  background: $pepe-dark-grey;
  color: $pepe-black-text;

  padding: 0 30px 0 12px;
  border-radius: 15px;

  margin-bottom: 12px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  font-family: $font2;
  font-size: 0.8em;
  line-height: 1em;

  .subtitle {
    font-size: 0.7em;
  }

  &.with-subtitle .title {
    font-size: 0.9em;
  }

  &:after {
    position: absolute;
    content: '';
    right: 5px;
    top: 8px;
    width: 20px;
    height: 20px;

    background: url('../../../static/img/icons/checkbox/not-checked.svg') no-repeat right center;
    background-size: 20px auto;
  }

  &.active::after {
    background-image: url('../../../static/img/icons/checkbox/checked.svg');
  }

  &:last-child {
    margin-bottom: 0;
  }
}
