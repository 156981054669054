@function get-vw($target) {
  $design-width: 320;
  $vw-context: ($design-width * .01) * 1px;
  @return ($target / $vw-context) * 1vw;
}

$width: get-vw(300px);
$height: get-vw(260px);
$tabs-selector-width: get-vw(49px);
$header-height: get-vw(32px);
$footer-height: get-vw(35px);
$tabs-height: $height - $footer-height;

$main-info-width: get-vw(94px);
$main-info-height: get-vw(100px);
$cancellation-width: get-vw(65px);
$total-price-width: get-vw(100px);

$tab-summary-height: $header-height;
$tab-info-height: get-vw(60px);
$tab-terms-height: get-vw(40px);
$tab-extras-height: get-vw(46px);
$tab-taxes-height: get-vw(47px);
