.ngdialog.cvc-dialog {
  .ngdialog-content {
    width: 600px;
  }
  .modal-dialog {
    width: auto;
    margin: 0;
    .modal-content {
      box-shadow: none;
      border: none;
      .text-description {
        font-family: $font2;
        font-size: 14px;
        color: $grey-dark;
        letter-spacing: 0;
        line-height: 18px;
      }
      .cards-wrapper {
        overflow: hidden;
        margin: 40px auto;
        width: 470px;
        .card {
          width: 205px;
          float: left;
          margin-right: 60px;
          &:last-of-type {
            margin-right: 0;
          }
          img {
            width: 100%;
          }
        }
      }
      .card-details-wrapper {
        font-family: $font2;
        font-size: 13px;
        color: $grey-dark;
        letter-spacing: 0;
        text-align: center;
        padding-right: 20px;
        p {
          margin: 0;
        }
        .card-name {
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
  }
}