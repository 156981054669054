@import "./filters-form-sass-variables";

.wrapper-filters-row {
  width: $wrapper-filters-row-width;
  display: inline-block;
  vertical-align: top;
  padding-right: 30px;
  .inner {
    max-height: calc(100% - 79px);
    overflow-y: auto;
    padding-bottom: 10px;
  }
  .wrapper-filters {
    width: 265px;
    .collapsed .content {
        display: block;
    }
  }
  

  .tooltip-container {
    display: block;
    position: relative;

    .fa-info-circle{
      cursor: help;
    }

    .filter-tooltip {
      background-color: $tooltip-bg-color;
      color: $tooltip-color;
      border-radius: 4px;
      max-width: $tooltip-max-width;
      min-width: $tooltip-min-width;

      position: absolute;
      z-index: 1070;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.42857;
      bottom: 100%;
      padding: 10px;
      text-align: center;
      text-decoration: none;
      text-shadow: none;
      text-transform: none;
      letter-spacing: normal;
      word-break: normal;
      word-spacing: normal;
      word-wrap: normal;
      white-space: normal;
    }
    .tooltip-arrow{
      position: absolute;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      bottom: -5px;

      border-width: 5px 5px 0;
      border-top-color: $tooltip-arrow-color;
    }
  }
}
