.wrapper-car .wrapper-tab-summary {
  .wrapper-row-1 {
    height: $tab-info-height;

    .wrapper-image {
      width: 40%;
      padding: get-vw-landscape(10px);
    }
    .wrapper-main-info {
      width: 60%;
    }
  }
  .wrapper-row-2 { height: $tab-terms-height; }
  .wrapper-row-3 { height: $tab-extras-height; }

  .wrapper-row-2 {
    overflow: hidden;

    .wrapper-characteristics {
      width: calc(100% - #{$cancellation-width});
    }

    .wrapper-cancellation {
      width: $cancellation-width;
    }
  }

  .wrapper-row-1 .wrapper-main-info {
    ul.list {
      padding: 0 get-vw-landscape(5px);
      margin: 0;
      height: 100%;
      flex-wrap: wrap;
      padding: get-vw-landscape(5px);
      justify-content: space-between;

      li {
        padding-left: get-vw-landscape(25px);
        margin-bottom: get-vw-landscape(8px);

        background: url('https://dummyimage.com/24x24/c7c5c8/fff') no-repeat 5px center;
        background-size: get-vw-landscape(12px) get-vw-landscape(12px);
        min-height: get-vw-landscape(12px);

        &.airport {
            width: 50%;
            margin-bottom: 0;
        }
        &.fuel-policy {
          width: 50%;
          margin-bottom: 0;
        }
        &.provider {
            width: 50%;

          .rating {
            width: get-vw-landscape(25px);
          }

          .text img {
            max-width: get-vw-landscape(45px);
            max-height: get-vw-landscape(20px);
            height: auto;
          }
        }
      }
    }
  }

  .wrapper-row-2 .wrapper-cancellation {

    .inner {
      width: get-vw-landscape(42px);
    }

    .title {
      margin-bottom: get-vw-landscape(2px);
    }
    .value {
      background-size: get-vw-landscape(25px) get-vw-landscape(15px);
    }
  }

  .wrapper-row-3 {
    > * {
      height: $tab-taxes-height;
    }

    .wrapper-daily-price {
      width: calc(100% - #{$total-price-width});
    }
    .wrapper-total-price {
      width: $total-price-width;
    }
  }

  .wrapper-row-3 .wrapper-daily-price {
    line-height: $tab-taxes-height;
  }

  .wrapper-row-3 .wrapper-total-price {
    padding-right: get-vw-landscape(12px);
  }
}
