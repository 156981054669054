@import "../../../sass/pepe-theme-sass-variables";

$header-height: 50px;
$footer-height: 80px;

.wrapper-submode-price-table {
  &.with-header.with-footer .wrapper-popup-inner {
    height: calc(100% - #{$footer-height} - #{$header-height});

    .list {
      margin: 0;
      padding: 0;
      li {
        width: 180px;
        height: 30px;

        margin: 0 auto;
        margin-bottom: 20px;

        list-style: none;
        border-radius: 15px;
        background: $white;

        overflow: hidden;

        &.type-price {
          .title { width: 85px; }
          .wrapper-price { width: 95px; text-align: center;  padding-right: 0; }
        }
        &.provider-price {
          .wrapper-image { width: 70px; }
          .wrapper-price { width: 110px; }
        }

        .wrapper-image,
        .title {
          float: left;
          height: 30px;
        }

        .title {
          line-height: 30px;
          width: 70px;
          text-align: center;
          text-transform: uppercase;
        }

        .wrapper-image {
          padding-left: 10px;

          display: flex;
          flex-direction: column;
          justify-content: center;

          img {
            max-width: 50px;
            height: auto;
            max-height: 30px;
          }
        }

        .wrapper-price {
          float: left;
          background: $grey;
          line-height: 30px;
          padding-right: 15px;
          text-align: center;

          font-family: $font2;
        }
      }
    }
  }

  .wrapper-popup-footer {
    height: $footer-height;
    padding: 0 30px;

    position: relative;

    .prev,
    .next {
      position: absolute;
      height: $footer-height;
      width: 30px;
      color: transparent;

      top: 0;

    }

    .prev {
      left: 0;
      background: url('../../../static/img/icons/row-left.svg') no-repeat;
      background-position: center center;
    }
    .next {
      right: 0;
      background: url('../../../static/img/icons/row-right.svg') no-repeat;
      background-position: center center;
    }

    .wrapper-list {
      overflow-x: auto;
      height: 100%;
    }
    .list {
      color: $pepe-black-text;
      font-family: $font2;
      font-size: 0.5em;
      height: 100%;
      margin: 0;
      padding: 0;
      overflow-x: auto;

      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: nowrap;

      li {
        font-size: 1em;
        transition: font-size 1s;
        margin: 0 10px;
        white-space: nowrap;

        line-height: 1em;
        list-style: none;

        &.active {
          font-size: 1.4em;
          font-weight: bold;
        }
      }

      .price {
        color: $pepe-dark-text;
      }
    }
  }
}
