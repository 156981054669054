$row-info-height: $inner-height / 2;
$row-characteristics-height: $inner-height / 4;
$row-provider-height: $inner-height / 4;

.car-result-box .wrapper-info {
  border: 1px solid $pepe-dark-grey;
  border-width: 0 1px 0 1px;
  position: relative;

  @import "car-row-info/component";
  @import "car-row-provider/component";
  @import "sass/wrapper-row-characteristics";
  @import "car-info-tooltip/component";

  .wrapper-booking-info,
  .wrapper-row-characteristics,
  .wrapper-row-provider {
    border-bottom: 1px solid $pepe-dark-grey;

    &:last-child { border-bottom: 0; }
  }
}
