.checkout-step-desktop {
  width: 95vw;
  .car-resume .car-resume-img-wrapper > img {
    width: 100%;
    height: auto;
    max-width: 230px;
  }
  .checkout-form-wrapper {
    .form-control {
      font-size: 0.9em;
    }
    .form-label {
      font-size: 0.7em;
    }
  }
  .payment-box {
    .checkout-payment-form-card {
      width: 100%;
      padding: 0 !important;
    }
  }
  .checkout-car-extras-box {
    .checkout-car-extras-heading {
      .checkout-car-extras-description {
        .checkout-car-extras-miniatures {
          display: none;
        }
      }
      .checkout-car-extras-price-box {
        padding: 0 35px;
      }
    }
    .checkout-car-extras-extra .checkout-car-extras-extra-price-box {
      padding: 0 35px;
      .checkout-car-extras-extra-price-description {

      }
    }
  }
  .checkout-submit-car-resume-wrapper {
    .car-resume {
      display: none;
    }
  }
  .checkout-submit-fixed .checkout-submit-wrapper {
    width: 95vw;
    padding: 0;
    .checkout-main-price {
      transform: translateX(120%);
    }
    .checkout-disclaimer,
    .checkout-submit-hover-button {
      display: none;
    }
    .img-wrapper {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      .car-image {
        transform: translateX(10%);
        width: 120%;
        height: auto;
        max-width: 110px;
      }
    }
  }
  .wrapper-payment-methods {
    .payment-methods {
      width: 100%;
      .payment-method {
        width: 100%;
      }
    }
    .payment-methods-info-list {
      display: none;
    }
  }
}

