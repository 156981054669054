@import "../../../sass/pepe-theme-sass-variables";

$height: 50px;

.wrapper-fixed-header {
  position: fixed;
  width: 100%;
  top: -($height + 10);
  left: 0;
  height: $height;
  background: $pepe-mid-grey;
  border-bottom: 1px solid $black;
  z-index: 5;
  text-transform: uppercase;
  color: $black;
  line-height: 1em;
  font-size: 0.9em;
  transition: top 1s;
  overflow: hidden;
  letter-spacing: 0.5px;
  box-shadow: 0 1px 10px $grey-mid2;

  &.visible { top: 0; }
}

.wrapper-fixed-header {
  .column {
    position: relative;
    height: $height;
    box-sizing: border-box;
    float: left;
    width: 50%;
    border-right: 1px solid $black;

    display: flex;
    flex-direction: column;
    justify-content: center;

    .inner {
      padding: 0 12px 0 30px;
    }
    .span-with-icon {
      display: inline-block;
      position: relative;
      &:after {
        display: block;
        content: '';
        width: 16px;
        height: 16px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -22px;
        margin: auto 0;
        background-size: 100% 100%;
      }
    }

    &:nth-child(2n) {
      text-align: center;
    }
    &:last-child {
      border-right: 0;
      text-align: right;
      .inner {
        padding-left: 10px;
        padding-right: 30px;
      }
      .span-with-icon:after {
        left: auto;
        right: -22px;
        top: 100%;
      }
    }
    $list: 'search-edit', 'price-table', 'change-order';
    @each $key in $list {
      &.action-#{$key} {
        .span-with-icon:after {
          background-image: url('../../../static/img/icons/header/#{$key}.svg');
        }
      }
    }

    .title { display: block; }

    .wrapper-select {
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      select {
        width: 100%;
        height: 100%;
        color: transparent;
        border: none;
        background: transparent;
      }
    }
  }
}
