.wrapper-datetime-range-input {
  display: flex;

  .wrapper-date-picker {
    width: 50%;
    .DateRangePickerInput {
      background: none;
      border: 0;
    }
    .DateRangePickerInput_arrow { display: none; }
    .DateInput {
      width: 100%;
      font-weight: 200;
      vertical-align: middle;
      border-radius: 10px;
      line-height: 40px;
      padding: 0 12px;
      font-family: $font2;
      background: $pepe-mid-grey;
      margin-bottom: 10px;
      font-size: inherit;
      color: $black;

      svg {
        display: none;
      }

      .DateInput_input {
        display: block;
        width: 100%;
        height: 100%;
        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE3LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhcGFfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiCgkgd2lkdGg9IjEycHgiIGhlaWdodD0iNnB4IiB2aWV3Qm94PSIwIDAgMTIgNiIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTIgNiIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxwYXRoIGZpbGw9IiMwMTAxMDEiIGQ9Ik0wLDBMNS45OTksNkwxMiwwIi8+Cjwvc3ZnPgo=) no-repeat right center;
        line-height: inherit;
        font-size: inherit;
      }

      .DateInput__display-text {
        padding: 0;
        background: url('../../../static/img/icons/wrapper-touch-select-placeholder.svg') no-repeat right center;
        background-size: 12px auto;
      }
    }
  }

  .wrapper-time {
    width: 50%;
    padding-left: calc(50% - 100px);

    .wrapper-touch-select-placeholder {
      width: 100%;
      input {
        background: none;
        border: 0;
      }
    }

    .touch-time-picker__container  {
      svg {
        display: none;
      }
    }
  }
}

.DateRangePicker__picker--portal {
  z-index: 100;
}

// mobile experiment
#SearchForm--Variant_B .search-box-mobile .wrapper-datetime-range-input {
  display: flex;
  background-color: white;
  border-radius: 12px;
  .wrapper-touch-date {
    width: unset;
    flex-grow: 1;
    .DateRangePickerInput {
      padding: unset;
      .DateInput {
        margin-bottom: 0;
        height: 56px;
        min-width: 176px;
        gap: 0;
        background-color: transparent;
        border-right: 1px solid #D4D4D4;
        svg {
          margin-right: 16px;
          display: block;
        }
        &_input {
          background: none;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          letter-spacing: 0.15px;
          color: #D4D4D4;
          &.filled {
            color: #3B3B3B;
          }
        }
        &:first-child {
          border-top-left-radius: 12px;
        }
        &:last-child {
          border-top: 1px solid #D4D4D4;
          border-bottom-left-radius: 12px;
        }
      }
    }
  }
  .wrapper-time {
    width: unset;
    .touch-time-picker__container  {
      margin: 0;
      gap: 0;
      height: 56px;
      width: 115px;
      background-color: transparent;
      svg {
        margin-right: 16px;
        display: block;
      }
      &:first-child {
        border-top-right-radius: 12px;
      }
      &:last-child {
        border-top: 1px solid #D4D4D4;
        border-bottom-right-radius: 12px;
      }
    }
    .touch-time-picker__content {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.15px;
      color: #3B3B3B;
    }
  }
}

// desktop
#SearchForm--Variant_B .search-box-desktop {
  .date-time-input {
    display: flex;
    background-color: $white;
    border-radius: 12px;
    position: relative;

    &.withRACSearchFormError {
      &.withRACSearchFormError--pickup-date-empty .date-selector-pickup,
      &.withRACSearchFormError--return-date-empty .date-selector-dropoff,
      &.withRACSearchFormError--pickup-time-invalid .time-selector--pick_up,
      &.withRACSearchFormError--return-time-invalid .time-selector--dropoff_hour {
        box-shadow: inset 0px 0px 0px 3px rgba(251, 164, 83, 0.5);
      }

      &.withRACSearchFormError--pickup-date-empty .date-selector-pickup {
        border-bottom-left-radius: 12px;
        border-top-left-radius: 12px;
      }

      &.withRACSearchFormError--return-time-invalid .time-selector--dropoff_hour {
        border-bottom-right-radius: 12px;
        border-top-right-radius: 12px;
      }

      .error-message {
        display: block;
      }
    }
    .date-selector {
      border-right:  0.5px solid #D4D4D4;
      height: 56px;
      width: 155px;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 16px 6px 16px 8px;
      &.editing {
        box-shadow: inset 0px 0px 0px 3px rgba(37, 148, 206, 0.3) !important;
      }
      &-dropoff {
        border-left: 2.5px solid #D4D4D4;
      }
      &_input {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.15px;
        color: #D4D4D4;
        text-transform: capitalize;
        &.filled {
          color: #3B3B3B;
        }
      }

    }
    .time-selector {
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 16px 8px;
      height: 56px;
      position: relative;
      width: 95px;
      &.editing {
        box-shadow: inset 0px 0px 0px 3px rgba(37, 148, 206, 0.3) !important;
      }
      input {
        border: none;
        background-color: transparent;
        height: 100%;
        max-width: 45px;
        padding: 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.15px;
        color: #3B3B3B;
      }
    }
    .error-message {
      display: none;
      width: 100%;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.4px;
      color: #FBA453;
      margin-left: 40px;
      position: absolute;
      top: 100%;
      left: 0;
    }
  }
}

// tablet
#SearchForm--Variant_B .search-box-tablet {
  .search-box-form {
    justify-content: unset !important;
    .date-time-input {
      background-color: white;
      border-radius: 12px;
      display: flex;
      .wrapper-date-picker {
        border-right: 0.5px solid #D4D4D4;
        width: 100%;
        .date-selector {
          width: 100%;
          height: 56px;
          padding: 16px !important;
          display: flex;
          align-items: center;
          gap: 19px;
          span {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: 0.15px;
            color: #D4D4D4;
            text-transform: capitalize;
            &.filled {
              color: #3B3B3B;
            }
          }
          &.date-selector-pickup {
            border-bottom: 0.5px solid #D4D4D4;
          }
        }
      }
      .wrapper-time {
        width: 100%;
        .touch-time-picker{
          &__container {
            background-color: transparent !important;
            height: 56px;
            margin-bottom: 0 !important;
            &:first-of-type {
              border-bottom: 0.5px solid #D4D4D4;
            }
          }
          &__content {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: 0.15px;
            color: #3B3B3B;
          }
        }
      }
    }
  }
}
