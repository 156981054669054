@import "../../../../src/sass/pepe-theme-sass-variables.scss";

#SearchForm--Variant_B {
  margin-bottom: 30px;
}

.wrapper-search-box.mobile {
  .wrapper-touch-checkbox {
    width: 100%;
  }
  .wrapper-time {
    padding: 0;
    .wrapper-touch-select-placeholder {
      width: 100%;
      margin-bottom: 10px;
      border-radius: 0;
      background: $pepe-grey;
      input {
        width: 100%;
      }
    }
  }
  .filled-button:hover {
    background-color: $pepe-red;
  }

  .margin-bottom-12 {
    margin-bottom: 12px;
  }
}

.SearchForm__Inner {
  padding: unset !important;
  background-image: url('../../../static/img/foto-portada-3.jpg') !important;
  max-height: inherit !important;
  .container {
    padding: unset !important;
  }
}

#SearchForm--Variant_B #search-box {
  position: relative;
  top: 0;
  width: 100%;
  background: transparent !important;
  border: unset !important;
  //height: calc(100vh - 60px) !important;
  #search-box-title {
    .SearchBoxTitle__Title {
      font-family: $font2 !important;
      font-style: normal !important;
      font-weight: 700 !important;
      font-size: 24px !important;
      line-height: 120% !important;
      letter-spacing: -0.5px !important;
      color: $white !important;
      text-transform: none !important;
      padding: 8px !important;
      margin-bottom: 18px!important;
    }
  }
  .tab-content {
    padding: 16px !important;
    height: 100% !important;
    display: flex !important;
    flex-direction: column !important;
  }
  form.search-box-form {
    //padding-top: 31px !important;
  }

  .wrapper-location-input {
    height: 56px;
    width: 100%;
    &.dual-top {
      .wrapper-touch-select-placeholder {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    &.dual-bottom {
      border-top: 1px solid #D4D4D4;
      .wrapper-touch-select-placeholder {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
    .wrapper-touch-select-placeholder {
      height: 100%;
    }
  }

  .form-group {
    margin-bottom: 0 !important;
  }

  .wrapper-checkbox-same-office {
    display: flex;
    color: white;
    align-items: center;
    padding: 12px 8px;
    order: 2;
    .checkbox-same-office {
      width: 16px;
      height: 16px;
      background: #FFFFFF;
      border-radius: 2px;
      margin-right: 8px;
    }
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.4px;
      color: #D4D4D4;
    }
  }

  .diff-office-close {
    position: absolute;
    right: 0;
    top: 0;
    height: 56px;
    display: flex;
    align-items: center;
    padding: 0 16px;
  }

  .dropoff-input-wrapper {
    position: relative;
  }

  .search-box-form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .btn.btn_search {
      font-family: $font2 !important;
      margin-top: 0px !important;
      border-radius: 3px;
      background: #D6322C !important;
      height: 56px;
      font-weight: 700 !important;
      font-size: 15px !important;
      line-height: 26px !important;
      text-align: center !important;
      letter-spacing: 0.46px !important;
      text-transform: uppercase !important;
    }
  }
  .error-message {
    display: none;
    width: 100%;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.4px;
    color: #FBA453;
    margin-left: 40px;
  }
  .withRACSearchFormError {
    display: flex;
    flex-direction: column;

    .wrapper-touch-select-placeholder {
      box-shadow: inset 0px 0px 0px 3px rgba(251, 164, 83, 0.5);
    }
    .wrapper-datetime-range-input {
      box-shadow: inset 0px 0px 0px 3px rgba(251, 164, 83, 0.5);
    }
    .date-selector.date-selector-pickup {
      border-top-left-radius: 12px;
    }
    .date-selector.date-selector-dropoff {
      border-bottom-left-radius: 12px;
    }
    .error-message {
      display: block;
    }
  }

  .SearchBoxForm--OneWay {
    .SearchForm__WrapperLocation {
      margin-bottom: 20px;
    }
  }
}
