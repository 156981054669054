.wrapper-car .wrapper-tab-selector li {
  &.tab-summary { height: $tab-summary-height; }
  &.tab-info { height: $tab-info-height; }
  &.tab-terms { height: $tab-terms-height; }
  &.tab-extras { height: $tab-extras-height; }
  &.tab-taxes { height: $tab-taxes-height; }

  span {
    padding-top: get-vw(22px);
    background-size: get-vw(25px) get-vw(20px);
  }

  &.tab-taxes span {
    padding-left: 10%;
    padding-right: 10%;
  }
}
