.wrapper-car .wrapper-layout .wrapper-tabs .wrapper-tab-active .wrapper-tab-terms {
  .inner-tab .content {
    font-family: "Montserrat", sans-serif;
    font-size: 0.6em;
  }

  &.loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    .wrapper-loading {
      text-align: center;
    }
  }
}
