html.root-desktop.wrapper-popup-visible {
  body { overflow: hidden; }
  &, body {
    height: 100%;
  }
  .pop-up-text {
    color: $pepe-black-text;
    font-family: $font2;
  }
  .pepe-popup-close-button {
    background: $pepe-red;
    border: none;
    color: $white;
    cursor: pointer;
    display: block;
    font-family: $font2;
    font-size: 1em;
    font-weight: 800;
    height: auto;
    letter-spacing: 1px;
    line-height: normal;
    margin-bottom: 0;
    margin-top: 10px;
    padding: 1.5em;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
  }
}

html.root-desktop {
  #modal {
    z-index: 1040;
    overflow: auto !important;
  }
}
