.wrapper-car-list {
  align-items: center;
}

.wrapper-notice {
  width: $width;
  font-size: 0.8em;
  margin-bottom: 20px;
}

.wrapper-car {
  width: $width;
  height: $height;
  margin-bottom: get-vw(10px);
  font-size: get-vw(14px);

  .wrapper-layout {
    height: $tabs-height;
    .wrapper-tab-selector {
      width: $tabs-selector-width;
    }

    .wrapper-tabs .wrapper-tab-active {
      height: calc(100% - #{$header-height});

      .inner-tab {
        .toggler .icon {

          top: 0;
          right: get-vw(5px);

          width: get-vw(40px);
          height: get-vw(40px);

          background: url('../../../../../static/img/icons/tab-closed.svg') no-repeat center center;
          background-size: get-vw(17px) get-vw(10px);
        }

        .toggler {
          padding: get-vw(5px);
        }
        .content {
          padding: get-vw(5px);
        }

        .toggler .visible-active,
        .toggler > .inner-tab-title {
          padding: get-vw(10px);
        }
      }
    }
  }
}
