.car-info-tooltip-big,
.car-info-tooltip-small-bottom,
.car-info-tooltip-small-top {
  width: 100%;
  padding: get-size(10px);
  border: 1px solid $pepe-dark-grey;
  position: absolute;
  line-height: 1.4em;
  font-size: 0.65em;
  left: 0;
  color: $grey-dark;

  &.small-text {
    font-size: 0.9em;
  }

  .inner ul {
    margin: 0;
    padding: 0;

    text-align: left;
    list-style-position: inside;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.car-info-tooltip-small-top {
  height: $row-provider-height;
  top: $row-info-height;
}

.car-info-tooltip-small-bottom {
  height: $row-provider-height;
  top: $row-info-height + $row-characteristics-height;
}

.car-info-tooltip-big,
.car-info-tooltip-small-top,
.car-info-tooltip-small-bottom {
  font-size: 1em;
  font-weight: bold;
}

.car-info-tooltip-big {
  top: $row-info-height;
  height: $row-info-height;
}
